import {apiSlice} from "../api/apiSlice";
import {Collection} from "../api/types";
import {LibraryEntry, LibraryItem} from "./LibraryTypes";
import {createSelector, createSlice, current, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../../store/clientStore";
import {extendedAuthApi} from "../auth/AuthSlice";
import {selectProductId} from "../product/ProductSlice";
import {arrayChunk, getEpoch} from "../../utils/Utils";


export const extendedLibraryApi = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getLibrary: builder.query<Collection<LibraryEntry>,
            { userId: string, page?: number, size?: number, sort?: string, filter?: string, expand?: string }>({
            query: ({userId, page = 0, size = 25, ...rest}) => ({
                url: `/user/${userId}/library`,
                params: {page, pageSize: size, ...rest}
            }),
            providesTags: (result, error, arg) =>
                result && result.data ?
                    [...result.data.map(library =>
                        ({type: "LibraryEntity" as const, id: library.productId})),
                        {type: "LibraryEntity", id: "PARTIAL-LIST"}] :
                    [{type: "LibraryEntity", id: "PARTIAL-LIST"}],
        }),
        getLibraryEntity: builder.query<LibraryEntry, { userId: string, productId: string, expand?: string }>({
            query: ({userId, productId, ...params}) => ({
                url: `/user/${userId}/library/${productId}`,
                params
            }),
            keepUnusedDataFor: 60 * 10,
            providesTags: (result, error, args) =>
                result ? [{type: "LibraryEntity", id: result.productId}, "LibraryEntity"] : ["LibraryEntity"]
        }),
        addToLibrary: builder.mutation<LibraryEntry, {
            userId: string,
            productId: string,
            curPage: number
        }>({
            query: ({userId, productId, curPage}) => ({
                url: `/user/${userId}/library`,
                method: "POST",
                body: {productId, curPage}
            }),
            async onQueryStarted({userId, productId, curPage}, {dispatch, queryFulfilled}) {

                try {
                    const { data } = await queryFulfilled;
                    dispatch(extendedLibraryApi.util.upsertQueryData("getLibraryEntity", { userId, productId }, data));
                } catch(error) {
                    console.error("Error adding item to library: ", error);
                }
            }
            // invalidatesTags: (result, error, args) =>
            //     result ? [{
            //         type: "LibraryEntity",
            //         id: args.userId + result.productId
            //     }, "LibraryEntity"] : ["LibraryEntity"]
        }),
        updateLibraryEntity: builder.mutation<LibraryEntry, {
            userId: string,
            productId: string,
            curPage: number,
        }>({
            query: ({userId, productId, ...body}) => ({
                url: `/user/${userId}/library/${productId}`,
                method: "POST",
                body
            }),

            async onQueryStarted({userId, productId, curPage}, {dispatch, queryFulfilled}) {
                // Update internal state before receiving a response.
                try {
                    const { data } = await queryFulfilled;
                    dispatch(
                        extendedLibraryApi.util.updateQueryData("getLibraryEntity", {userId, productId}, (draft) => {
                            Object.assign(draft, data);
                        })
                    );
                } catch(error) {
                    console.error("Error updating library entry: ", error);
                }
            }
        })
    })
});


export interface InitialState {
    book: Record<string, LibraryItem> & { total?: number };
}

export const initialState: InitialState = {
    book: {},
} as const;

const librarySlice = createSlice({
    name: "library",
    initialState,
    reducers: {
        addItemToLibrary: (state, {
            payload: {
                productId,
                curPage,
            }, type
        }: PayloadAction<{ productId: string, curPage: number }>) => {
            const completedPage = current(state.book)[productId]?.completedPage ?? 0;
            state.book[productId] = {
                productId,
                curPage: curPage ?? 1,
                completedPage: (curPage ?? 1) > completedPage ? (curPage ?? 1) : completedPage,
                createdDate: getEpoch(),
                lastModifiedDate: getEpoch(),
            }
        },
        updateLibraryItem: (state,
                            {payload: {id, curPage}, type}: PayloadAction<{ id: string, curPage: number }>) => {
            if (state.book[id] !== undefined) {
                const completed = current(state.book[id]).completedPage;
                state.book[id].curPage = curPage;
                state.book[id].completedPage = curPage > (completed ?? 0) ? curPage : completed;
                // TODO add local time offset, lastModifiedDate is used to determine local vs remote value should be used
                state.book[id].lastModifiedDate = getEpoch();
            } else { console.error("Attempting to update library entry when library entry has not been added yet."); }
        },
    },
    extraReducers: builder =>
        builder
            .addMatcher(extendedLibraryApi.endpoints.getLibrary.matchFulfilled,
                (state, {payload, type}) => {
                    state.book.total = payload.totalElements;
                    if (payload.data) {
                        payload.data.forEach(({productId, curPage, completedPage, createdDate, lastModifiedDate}) => {
                            const cur = current(state.book)[productId];
                            if (cur.lastModifiedDate < lastModifiedDate)
                                state.book[productId] = {
                                    productId, curPage, completedPage, createdDate, lastModifiedDate,
                                };
                        })
                    }
                })
            .addMatcher(extendedLibraryApi.endpoints.getLibraryEntity.matchFulfilled,
                (state, {
                    payload:
                        {productId, curPage, completedPage, createdDate, lastModifiedDate}, type
                }) => {
                    const cur = current(state.book)[productId];
                    // Merge remove book state with local, need to determine if local state is valid,
                    // Maybe also dif created date with last modified date, to help determine with state value
                    // should be excepted.
                    if (!cur || lastModifiedDate > cur.lastModifiedDate) {
                        state.book[productId] = {productId, curPage, completedPage, createdDate, lastModifiedDate};
                    }
                })
            // .addMatcher(extendedLibraryApi.endpoints.addToLibrary.matchFulfilled,
            //     (state, {payload: {productId, curPage, completedPage, createdDate, lastModifiedDate}, type}) => {
            //         console.log("addToLibrary matchFulfilled");
            //         state.book[productId] = {productId, curPage, completedPage, createdDate, lastModifiedDate};
            //     })
            .addMatcher(extendedAuthApi.endpoints.logout.matchFulfilled,
                (state, {payload, type}) => {
                    return initialState;
                })
            .addMatcher(extendedAuthApi.endpoints.login.matchFulfilled,
                (state, {payload, type}) => {})

});

type LibrarySorter<T> = Record<LibrarySort, (a: T, b: T) => number>

const librarySorter: LibrarySorter<LibraryEntry> = {
    curPage: (a, b) => a.curPage - b.curPage,
    // completed: (a, b) => {
    //     if (a.completed && b.completed)
    //         return 0;
    //     if (a.completed)
    //         return 1;
    //     return -1;
    // },
    lastModifiedDate: (a, b) => a.lastModifiedDate && b.lastModifiedDate ?
        a.lastModifiedDate - b.lastModifiedDate : a.lastModifiedDate ? 1 : -1,
    createdDate: (a, b) => a.createdDate && b.createdDate ?
        a.createdDate - b.createdDate : a.createdDate ? 1 : -1,
}


export type LibrarySort = "curPage" | /* "completed" | */ "lastModifiedDate" | "createdDate";

function isLibraryEntry(entry: any): entry is LibraryEntry {
    return (entry as LibraryEntry).productId !== undefined;
}

export const selectSortedLibraryEntities = createSelector(
    (state: RootState) => state.library.book,
    (state: RootState, sort: LibrarySort) => sort,
    (state: RootState, sort: LibrarySort, page: number) => page,
    (state: RootState, sort: LibrarySort, page: number, size: number) => size,
    (libraryEntities, sort, page, size) => arrayChunk(
        Object.values(libraryEntities)
            .filter(isLibraryEntry)
            .sort(librarySorter[sort]),
        size)[page]);


export const selectLibraryEntries = (state: RootState) => state.library.book;
export const selectLibraryEntry = (state: RootState, productId?: string) =>
    productId ? state.library.book[selectProductId(state, productId)] : undefined;

export const selectCurrentPageNumber = (state: RootState, productId: string | undefined) =>
    productId ? state.library.book[selectProductId(state, productId)]?.curPage : 1;

export const {
    addItemToLibrary,
    updateLibraryItem,
} = librarySlice.actions;

export const {
    useGetLibraryQuery,
    useGetLibraryEntityQuery,
    useAddToLibraryMutation,
    useUpdateLibraryEntityMutation,
} = extendedLibraryApi;

export default librarySlice.reducer;