import React, {PropsWithChildren, useMemo} from "react";
import {selectAuthorities, selectIsAuthenticated} from "../feature/auth/AuthSlice";
import {Authority} from "../feature/auth/AuthTypes";
import {useAppSelector} from "../store/hooks";

type Props = PropsWithChildren<{
    userMustBeAuthenticated?: boolean; // This user must be authenticated
    AuthenticationNotRequired?: boolean; // This component may be visible to authenticated user and unauthenticated user
    hasAny?: Authority[];
    hasAll?: Authority[];
    override?: boolean; // Override authentication independently of permissions.
    alternative?: React.ReactNode;
}>

function ComponentGuard({
                            hasAny = [],
                            hasAll = [],
                            userMustBeAuthenticated = hasAny.length > 0 || hasAll.length > 0,
                            override = false,
                            alternative = "",
                            children
                        }: Props) {
    // Get users authorities
    const isAuthenticated = useAppSelector(selectIsAuthenticated);
    const authorities = useAppSelector(selectAuthorities);

    const isAllowed: boolean = useMemo(() => {

        // if the user must NOT be authenticated, ( Only unauthenticated user can view this ),
        // and authentication IS required, return the users authentication status to determine access
        if (!userMustBeAuthenticated)
            return !isAuthenticated; // return the users authentication status

        // if user is not authenticated, or they don't have any authorities deny access
        if (!isAuthenticated || !authorities)
            return false;

        // Verify that the user contains all required authorities
        if (!hasAll.reduce((acc, cur) =>
            (acc && authorities.includes(cur)), true))
            return false;

        // Verify the user contains any of the required authorities
        return hasAny.reduce((acc, cur) =>
            (acc || authorities.includes(cur)), hasAny.length === 0);

    }, [authorities, hasAll, hasAny, isAuthenticated, userMustBeAuthenticated]);

    return (
        <>
            {override ? children : (isAllowed ? children : alternative)}
        </>
    );
}

export default ComponentGuard;