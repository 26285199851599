import {apiSlice} from "../api/apiSlice";
import {createSlice, isAnyOf, PayloadAction} from "@reduxjs/toolkit";
import {Auth} from "../api/types";
import {RootState} from "../../store/clientStore";
import {extendedShoppingApi} from "../shopping/ShoppingSlice";
import {extendedUserApi} from "../user/UserSlice";
import {AuthAction, DeleteAccount} from "./AuthTypes";

export const extendedAuthApi = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getAuth: builder.query<Auth | void, void>({
            query: () => `/auth/login`,
            providesTags: ["Auth"]
        }),
        login: builder.mutation<Auth, { email: string, password: string, rememberMe: boolean }>({
            query: ({email, password, rememberMe}) => ({
                url: `/auth/login`,
                method: "POST",
                body: {email, password},
                params: {"remember-me": rememberMe}
            }),
            async onQueryStarted({ }, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    dispatch(extendedAuthApi.util.upsertQueryData("getAuth", undefined, data));
                } catch (error) {
                    // console.error("Login Error: ", error);
                }
            }
        }),
        logout: builder.mutation<void, { userId: string }>({
            query: () => ({
                url: "/auth/logout",
                method: "POST"
            }),
            onQueryStarted({ userId }, {dispatch, queryFulfilled}) {
                queryFulfilled
                    .then(() => {
                        dispatch(extendedAuthApi.util.upsertQueryData("getAuth", undefined, undefined));
                        /* @ts-ignore */ // I need to clear the remote cart data from redux without running a new query, which will just throw an error
                        dispatch(extendedShoppingApi.util.upsertQueryData("getCurrentCart", { userId }, undefined));
                        // Clear any data in the user's profile cache
                        dispatch(extendedUserApi.util.upsertQueryData("getUserProfile", { id: userId }, { id: userId }));
                    }).catch(error => {
                    console.error("An error occurred while logging out: ", error)
                })
            },
            invalidatesTags: ["CSRF", "LibraryEntity", "UserProfile"]
        }),
        resetPasswordRequest: builder.mutation<void, { email: string }>({
            query: (email) => ({
                url: "/auth/reset-password-request",
                method: "POST",
                body: email
            })
        }),
        resetPassword: builder.mutation<void,
            { email: string, password: string, token: string | null }>({
            query: (newPassword) => ({
                url: "/auth/reset-password",
                method: "POST",
                body: newPassword
            })
        }),
        activateAccount: builder.mutation<void, { token: string | null }>({
            query: token => ({
                url: "/auth/activate-account",
                method: "POST",
                body: token
            })
        }),
        sendActivateAccountCode: builder.mutation<void, { email: string }>({
            query: body => ({
                url: "/auth/create-activate-account",
                method: "POST",
                body
            })
        }),
        oAuthLogin: builder.query<Auth, {provider?: string; state: string | null; code?: string | null; scope?: string | null; authuser?: string | null; prompt?: string | null; }>({
            query: ({provider, state, code, scope, authuser, prompt}) => ({
                url: `/auth/oauth2/code/${provider}`,
                method: "GET",
                params: {state, code, scope, authuser, prompt, "remember-me": true}
            })
        }),
        requestDeleteAccount: builder.mutation<void, { userId: string }>({
            query: ({ userId }) => ({
                url: `/auth/${userId}/delete-account-request`,
                method: "POST",
            })
        }),
        getDeleteAccountData: builder.query<DeleteAccount, { userId: string}>({
            query: ({ userId }) => ({
                url: `/auth/${userId}/delete-account`,
                method: "GET"
            })
        }),
        deleteAccountData: builder.mutation<void, {userId: string, data: Required<DeleteAccount>}>({
            query: ({ userId, data: body }) => ({
                url: `/auth/${userId}/delete-account`,
                method: "POST",
                body
            }),
            invalidatesTags: ["LibraryEntity", "UserProfile"]
        })
    })
});

export interface InitialState {
    csrf?: {
        token: string;
        date: number;
    };
    isAuthenticated: boolean;
    isAuthenticating: boolean;
    user?: Auth;
}

export const initialState: InitialState = {
    isAuthenticated: false,
    isAuthenticating: false,
} as const;


const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setCsrf(state, action) {
            state.csrf = {
                token: action.payload.token,
                date: Date.now()
            };
        },
        setAuth(state, { payload }: PayloadAction<AuthAction>) {
            state.isAuthenticated = payload.isAuthenticated;
            state.isAuthenticating = false;
            state.user = payload.isAuthenticated ? payload.user : undefined;
        }
    },
    extraReducers: builder => {
        builder
            .addMatcher(
                isAnyOf(extendedAuthApi.endpoints.login.matchPending,
                    extendedAuthApi.endpoints.getAuth.matchPending,
                    extendedAuthApi.endpoints.oAuthLogin.matchPending),
                (state, action) => {
                    state.isAuthenticating = true;
                })
            .addMatcher(isAnyOf(extendedAuthApi.endpoints.login.matchFulfilled,
                    extendedAuthApi.endpoints.oAuthLogin.matchFulfilled),
                (state, action) => {
                    console.log(`User ${action.payload.email} logged in.`);
                    state.user = {
                        id: action.payload.id,
                        email: action.payload.email,
                        authorities: Object.values(action.payload.authorities ?? {})
                    };
                    state.isAuthenticating = false;
                    state.isAuthenticated = true;
                })
            .addMatcher(extendedAuthApi.endpoints.getAuth.matchFulfilled,
                (state, action) => {
                    if (action.payload != null) {
                        state.user = {
                            id: action.payload.id,
                            email: action.payload.email,
                            authorities: Object.values(action.payload.authorities ?? {})
                        };
                        state.isAuthenticated = true;
                    }
                    state.isAuthenticating = false;
                })
            .addMatcher(isAnyOf(extendedAuthApi.endpoints.login.matchRejected,
                    extendedAuthApi.endpoints.getAuth.matchRejected,
                    extendedAuthApi.endpoints.oAuthLogin.matchRejected),
                (state, action) => {
                    state.isAuthenticating = false;
                    state.isAuthenticated = false;
                    state.user = undefined;
                })
            .addMatcher(isAnyOf(extendedAuthApi.endpoints.logout.matchFulfilled), (state, action) => {
                state.isAuthenticated = false;
                state.isAuthenticating = false;
                state.user = undefined;
            })
            .addMatcher(extendedAuthApi.endpoints.getCSRF.matchFulfilled, (state, action) => {
                state.csrf = {
                    token: action.payload.token,
                    date: Date.now()
                };
            })
            .addMatcher(extendedAuthApi.endpoints.getCSRF.matchRejected, (state, action) => {
                console.log("Could not get csrf token.");
            })
    }
});


export const selectIsAuthenticated = (state: RootState) => state.auth.isAuthenticated;
export const selectIsAuthenticating = (state: RootState) => state.auth.isAuthenticating;
export const selectCurrentUser = (state: RootState) => state.auth.user;
export const selectAuthorities = (state: RootState) => state.auth.user?.authorities;
export const selectUserAuth = (state: RootState) => state.auth;

export const {
    useGetAuthQuery,
    useLoginMutation,
    useLogoutMutation,
    useResetPasswordRequestMutation,
    useResetPasswordMutation,
    useActivateAccountMutation,
    useSendActivateAccountCodeMutation,
    useOAuthLoginQuery,
    useRequestDeleteAccountMutation,
    useGetDeleteAccountDataQuery,
    useDeleteAccountDataMutation,
} = extendedAuthApi;

export const {setCsrf, setAuth} = authSlice.actions;


export const {
    endpoints: {getCSRF, getAuth}
} = extendedAuthApi;

export default authSlice.reducer;



