import {useEffect, useMemo} from "react";
import {useLocation} from "react-router";
import {toUppercase} from "../../utils/StringUtils";


function useMetaTags() {

    const location = useLocation();

    const pathVariables = useMemo(() => {
        return location.pathname.split("/")
            .filter(s => Boolean(s))
            .map(s => s.trim().toLowerCase());
    },[location.pathname]);


    useEffect(() => {
        if (document) {
            if (pathVariables.length === 0) {
                document.title = "Aurora Books";
            } else if (pathVariables[0] === "faq") {
                document.title = "Frequently Asked Questions";
            } else if (pathVariables[0] === "auth" || pathVariables[0] === "product" || pathVariables[0] === "book") {
                document.title = `Aurora Books - ${toUppercase(pathVariables[1], '-')}`;
            } else {
                document.title = `Aurora Books - ${toUppercase(pathVariables[0], '-')}`
            }
        }
    }, [pathVariables]);
}

export default useMetaTags;