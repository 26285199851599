import {useSelector} from "react-redux";
import {
    selectAuthorities,
    selectCurrentUser,
    selectIsAuthenticated,
    selectIsAuthenticating
} from "../feature/auth/AuthSlice";
import {Navigate, Outlet, To, useLocation} from "react-router";
import {useEffect, useMemo, useRef} from "react";
import {Authority} from "../feature/auth/AuthTypes";
import {Auth} from "../feature/api/types";


interface RouteGuardProps {
    isAuthenticated?: boolean,
    hasAny?: Authority[];
    hasAll?: Authority[];
    // Function that can define if the user can access the resource, available for more complex access control decisions.
    fulfills?: (user: Auth, authorities: Authority[]) => boolean;
    redirectTo?: To;
    saveLocation?: boolean;
}

function RouteGuard({
                        isAuthenticated: requiresAuthentication = true,
                        hasAny = [],
                        hasAll = [],
                        fulfills,
                        redirectTo = "/",
                        saveLocation = false,
                    }: RouteGuardProps) {

    const location = useLocation();

    const isAuthenticating = useSelector(selectIsAuthenticating);
    const isAuthenticated = useSelector(selectIsAuthenticated);
    const curUser = useSelector(selectCurrentUser);
    const authorities = useSelector(selectAuthorities) as Authority[];

    const allow: boolean = useMemo(() => {
        if (isAuthenticating)
            return true;

        // If the route does not require authentication
        if (!requiresAuthentication) {
            return !isAuthenticated; // only allow if the user is not authenticated
        }

        // If the user is not authenticated, deny access,
        // or if the current user or the users authorities are not truthy deny access.
        if (!isAuthenticated || !curUser || !authorities)
            return false;

        // if a fulfills function is provided, execute it,
        // if fulfills fails deny access
        if (fulfills != null && !fulfills(curUser, authorities))
            return false;

        // Verify that the user contains all required authorities
        if (!hasAll.reduce((acc, cur) =>
            (acc && authorities.includes(cur)), true))
            return false;

        // Verify the user contains any of the required authorities
        return hasAny.reduce((acc, cur) =>
            (acc || authorities.includes(cur)), hasAny.length === 0);

    }, [requiresAuthentication, isAuthenticated, isAuthenticating, curUser, authorities, fulfills, hasAll, hasAny]);

    return allow ? <Outlet/> :
        <Navigate to={location.state?.path ?? redirectTo}
                  replace={true}
                  // If redirecting to a saved location, clear that location on redirect
                  state={location.state?.path ? {} :
                      // Save this location
                      saveLocation ? {path: location.pathname} : {}}
        />;
}

export default RouteGuard;