import React, {FC, MouseEventHandler, PropsWithChildren, ReactNode, useCallback, useState} from "react";
import {Delete} from "@mui/icons-material";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    SxProps,
    IconButton,
    DialogContentText, Theme
} from "@mui/material";
import {Breakpoint} from "@mui/system";

interface ConfirmActionProps<R> {
    component?: FC<{ onClick: MouseEventHandler<HTMLElement>, disabled: boolean, sx?: SxProps<Theme> }>;
    actions?: FC<{
        onConfirm: MouseEventHandler<HTMLElement>,
        onCancel: MouseEventHandler<HTMLElement>,
        'data-id'?: string;
        buttonText?: string
    }>;
    onCommit: (event: React.MouseEvent<HTMLElement>) => R;
    title?: string;
    content?: string | string[] | ReactNode;
    disabled?: boolean;
    actionBtnText?: string;
    maxWidth?: Breakpoint;
    sx?: SxProps<Theme>;
}

function ConfirmAction<R>({
                              component: Component = (
                                  ({onClick, disabled, sx}) =>
                                      <IconButton sx={[...(Array.isArray(sx) ? sx : [sx])]}
                                                  disabled={disabled}
                                                  onClick={onClick}><Delete/></IconButton>),

                              actions: Actions = ({onCancel, onConfirm, buttonText = "Delete", ...props}) => (
                                  <>
                                      <Button onClick={onCancel} color="inherit">Cancel</Button>
                                      <Button
                                          onClick={onConfirm}
                                          color="error"
                                          variant="contained"
                                          {...props}
                                      >{buttonText}</Button>
                                  </>
                              ),
                              onCommit,
                              title = "Confirm Delete",
                              disabled = false,
                              content,
                              children,
                              actionBtnText = "Delete",
                              maxWidth = "sm",
                              sx,
                              ...props
                          }: PropsWithChildren<ConfirmActionProps<R>>) {

    const [state, setState] = useState<{ open: boolean }>({open: false});

    const handleOpenDialog = useCallback<MouseEventHandler<HTMLElement>>(
        (event) => {
            // prevent parent components from reacting to this click event.
            event.stopPropagation();
            setState({open: true});
        }, []);
    const handleCloseDialog = useCallback((event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setState(state => ({...state, open: false}));
    }, []);

    const handleCommit = useCallback<MouseEventHandler<HTMLElement>>((
        event) => {
        onCommit(event);
        handleCloseDialog(event);
    }, [onCommit, handleCloseDialog]);

    return (
        <>
            <Component sx={sx} disabled={disabled} onClick={handleOpenDialog}/>

            <Dialog
                aria-modal
                maxWidth={maxWidth}
                onClose={handleCloseDialog}
                open={state.open}>
                <DialogTitle align="center">{title}</DialogTitle>
                <DialogContent>
                    {content &&
                    Array.isArray(content) ? content.map((t, i) => <DialogContentText key={i}>{t}</DialogContentText>) :
                        content
                    }
                </DialogContent>
                {/*{*/}
                {/*    children &&*/}
                {/*    <DialogContent>*/}
                {/*        {children}*/}
                {/*    </DialogContent>*/}
                {/*}*/}

                <DialogActions sx={{justifyContent: "space-between"}}>
                    <Actions onConfirm={handleCommit}
                             onCancel={handleCloseDialog}
                             buttonText={actionBtnText}
                             {...props}
                    />
                </DialogActions>
            </Dialog>
        </>
    );
}

export default ConfirmAction;