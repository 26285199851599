'use client';

import React, {useEffect} from 'react';
import AppMenu from "./components/AppMenu";
import {Outlet, useLocation, useSearchParams} from "react-router";
import CartManager from "./feature/shopping/CartManager";
import {MenuControl} from "./components/MenuController";
import {Box} from "@mui/material";
import {setAuth, useGetAuthQuery} from "./feature/auth/AuthSlice";
import ReactGA from "react-ga4";
import {InstallPWA} from "./components/InstallPWAContext";
import {useAppDispatch} from "./store/hooks";
import {setEarlyAccessPref} from "./feature/settings/SettingsSlice";
import {useBroadcast} from "./components/BroadcastController";
import useMetaTags from "./feature/social/UseMetaTags";

function App() {
    const location = useLocation();
    const [params, setUrlSearchParams] = useSearchParams();

    // Get the users current authentication status
    useGetAuthQuery();
    const dispatch = useAppDispatch();

    useEffect(() => {
        const preview = params.get("preview");
        if (preview &&
            (preview.toLowerCase() === 'true'
                || preview.toLowerCase() === 'yes'
                || preview.toLowerCase() === 'enabled')) {

            dispatch(setEarlyAccessPref({
                enabled: true
            }));

            params.delete("preview");
            setUrlSearchParams(params);

            console.info("Enable Preview Components.");
            console.info("You can customize the preview components that appear within the settings route.");
        }
    }, [params]);

    const {addListener} = useBroadcast("AUTH");

    useEffect(() =>
            addListener((auth) => {
                console.log(auth.isAuthenticated ? "Login Detected: " + auth.user.email : "Logout Detected");
                dispatch(setAuth(auth));
            })
        , []);

    useEffect(() => {
        if (import.meta.env.MODE === "product")
            ReactGA.send({
                hitType: "pageview",
                page: location.pathname,
            });
    }, [location]);

    // Update title tag dynamically, during route navigation
    useMetaTags();

    return (
        <InstallPWA>
            <CartManager>
                <MenuControl>
                    <Box sx={(theme) => ({
                        maxWidth: theme.breakpoints.values.xl,
                        minHeight: "100vh",
                        mx: "auto",
                        backgroundColor: theme.palette.background.paper,
                        boxShadow: "0 0 10px rgba(0,0,0,0.2)",
                        padding: 0,
                        position: "relative",
                    })}>
                        <AppMenu/>
                        <Outlet/>
                        {/*<BottomMenu />*/}
                    </Box>
                </MenuControl>
            </CartManager>
        </InstallPWA>
    );
}

export default App;
