import {isRejectedWithValue, Middleware, MiddlewareAPI} from "@reduxjs/toolkit";
import {capitalizeFirstLetter} from "../../utils/StringUtils";


function transformDetails(details: string | { [key: string]: string }) {
    if (typeof details === "string")
        return [details];
    else if (typeof details === "object") {
        return Object.entries(details).reduce((acc: string[], cur) => {
            acc.push(capitalizeFirstLetter(cur[0]) + ": " + capitalizeFirstLetter(cur[1]));
            return acc;
        }, []);
    } else return null;
}

export type ResponseError = {
    status: number | string;
    timestamp?: string;
    details?: string[];
    message?: string;
    code?: string;
    data?: any;
};


export function isResponseError(error: unknown): error is ResponseError {
    return error != null && typeof error === "object" && "status" in error;
}

export const RtkQueryErrorTransformer: Middleware = (api: MiddlewareAPI) =>
    (next) => (action) => {

        if (isRejectedWithValue(action)) {
            // console.error(action);
            if (isResponseError(action.payload)) {
                const payload = action.payload;
                action.payload = {
                    status: payload.status,
                    timestamp: payload?.data?.timestamp,
                    message: payload?.data?.message,
                    details: transformDetails(payload?.data?.error),
                    code: payload?.data?.code,
                };

            } else {
                console.error(`Serialization error: `, action);
                action.payload = {
                    name: "Serialization Error",
                    message: "Unknown serialization error.",
                    code: "serializationError"
                };
            }
        }
        next(action);
    }