import {createContext, PropsWithChildren, useContext, useEffect, useRef} from "react";
import {Auth} from "../feature/api/types";


export type AuthEvent = { isAuthenticated: true, user: Auth } | {
    isAuthenticated: false
};

export interface BroadcastEvents {
    AUTH: AuthEvent;
}

type Channel = keyof BroadcastEvents;

export type BroadcastEventListener<K extends Channel> = (data: BroadcastEvents[K]) => void;

type RemoveBroadcastEventListener = () => void;

interface BroadcastMethods {
    emit: <C extends Channel>(channel: Channel, data: BroadcastEvents[C]) => void;
    addListener: <C extends Channel>(channel: C, listener: BroadcastEventListener<C>) => RemoveBroadcastEventListener;
}

export const BroadcastContext = createContext<BroadcastMethods>({
    emit: (channel, data) => {},
    addListener: (channel, listener) => () => {},
});


export function BroadcastController(props: PropsWithChildren) {

    const bc = useRef<Record<Channel, BroadcastChannel>>({} as Record<keyof BroadcastEvents, BroadcastChannel>);
    const listeners = useRef<Record<Channel, BroadcastEventListener<Channel>[]>>({"AUTH": []});

    useEffect(() => {
        // console.log("Creating Broadcast controllers");
        bc.current = {
            AUTH: new BroadcastChannel("AUTH")
        };

        // Add a listener to each broadcast controller
        Object.entries(bc.current)
            .forEach(([channel, broadcast]) =>
                broadcast.onmessage = <K extends Channel>(event: MessageEvent<BroadcastEvents[K]>) => {
                   listeners.current[channel as K].forEach(listener => listener(event.data));
            });

        // Close all broadcast listeners
        return () => Object.values(bc.current).forEach((bc => bc.close()));
    }, []);

    const controller: BroadcastMethods = {
        emit(channel, event){
            bc.current[channel].postMessage(event);
        },
        addListener<C extends Channel>(channel: C, nl: BroadcastEventListener<Channel>) {
            // console.log("Broadcast Event Listener Added.");
            if (!listeners.current[channel])
                listeners.current[channel] = [];

            listeners.current[channel].push(nl);

            // Return the close function, this will work easily with useEffect
            return () => {
                // console.log("Broadcast Event Listener Removed.");
                listeners.current[channel].splice(listeners.current[channel].indexOf(nl), 1);
            }
        }
    };

    return (
        <BroadcastContext.Provider
            value={controller}
            {...props}
        />
    );
}

// This hook provides methods to interact with the broadcast controller without needed to mess with context
export function useBroadcast<T extends Channel>(channel: T) {

    const {emit, addListener } = useContext(BroadcastContext);

    return {
        emit(data: BroadcastEvents[T]) {
            emit(channel, data);
        },

        addListener( listener: BroadcastEventListener<T>) {
            return addListener(channel, listener);
        }
    };
}