import {ReactNode, useContext, useEffect, useId, useState} from "react";

import {
    alpha,
    AppBar,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Theme,
    Toolbar,
    Typography
} from "@mui/material";

import {NavLink, useLocation} from "react-router";
import {useSelector} from "react-redux";
import {selectCurrentUser, selectIsAuthenticated} from "../feature/auth/AuthSlice";
import {RoutePath} from "../router/RoutePath";
import ComponentGuard from "./ComponentGuard";

import {
    AccountBox,
    AdminPanelSettings as AdminPanelSettingsIcon,
    AlternateEmail as AlternateEmailIcon,
    BugReport as BugReportIcon,
    ChevronRight,
    History,
    Home as HomeIcon,
    InfoOutlined as InfoOutlinedIcon,
    InstallMobile as InstallMobileIcon,
    Login,
    Logout,
    Menu as MenuIcon,
    QuestionMark,
    Settings as SettingsIcon,
    ShoppingCart
} from "@mui/icons-material";

import "@fontsource/tangerine/700.css";
import {authority} from "../feature/auth/AuthTypes";
import HideOnScroll from "./HideOnScroll";
import {MenuControlContext} from "./MenuController";
import {SystemStyleObject} from "@mui/system";
import {InstallPWAContext} from "./InstallPWAContext";
import ReportBugForm, {ReportBugData} from "../feature/support/forms/ReportBugForm";
import {captureFeedback} from "@sentry/react";
import LogoutAction from "../feature/auth/components/LogoutAction";


const menuStyling: Record<"main" | "noLogo" | "reader" | "none", Record<"appbar" | "menu" | "logo" | "toolbar", (theme: Theme) => SystemStyleObject<Theme>>> = {
    main: {
        appbar: (theme) => ({
            boxShadow: "none",
            backgroundImage: "none",
            // border: `1px solid ${theme.palette.divider}`,
            // backgroundColor: alpha(theme.palette.background.paper, 0.9),
            backdropFilter: "blur(2px)",
            maxWidth: "xl",
            mx: "auto"
        }),
        toolbar: (theme) => ({
            maxWidth: "xl",
            mx: "auto"
        }),
        menu: (theme) => ({}),
        logo: (theme) => ({})
    },
    noLogo: {
        appbar: (theme) => ({
            boxShadow: "none",
            backgroundColor: "transparent",
            backgroundImage: "none"
        }),
        toolbar: (theme) => ({
            maxWidth: "xl",
            mx: "auto"
        }),
        menu: (theme) => ({
            backgroundColor: alpha(theme.palette.background.paper, 0.3),
            backdropFilter: "blur(3px)",
            border: `1px solid ${alpha(theme.palette.background.paper, 0.8)}`,
            borderRadius: 2
        }),
        logo: (theme) => ({
            display: "none"
        })
    },
    reader: {
        appbar: (theme) => ({
            boxShadow: "none",
            backgroundImage: "none",
            backgroundColor: "transparent"
        }),
        toolbar: (theme) => ({
            maxWidth: "xl",
            mx: "auto"
        }),
        menu: (theme) => ({
            backgroundColor: alpha(theme.palette.background.paper, 0.3),
            backdropFilter: "blur(3px)",
            border: `1px solid ${alpha(theme.palette.background.paper, 0.8)}`,
            borderRadius: 2
        }),
        logo: (theme) => ({
            display: "none"
        })
    },
    none: {
        appbar: (theme) => ({
            display: "none"
        }),
        toolbar: (theme) => ({}),
        menu: (theme) => ({}),
        logo: (theme) => ({})
    }
} as const;

type MenuKeys = keyof typeof menuStyling;
type FeedBackModel = 'OPEN' | 'SENT' | 'CLOSED';

const menuStyleSelector: Record<MenuKeys, ((loc: string[]) => boolean)[]> = {
    noLogo: [(loc) => loc[0] === 'product', (loc) => loc[0] === 'about'],
    reader: [(loc) => loc[0] === 'book'],
    none: [(loc) => loc[0] === 'auth' && loc[1] === 'oauth2'],
    main: [(loc) => true]
}

function AppMenu() {

    const {showTopMenu} = useContext(MenuControlContext);

    const [open, setOpen] = useState(false);
    const isAuthenticated = useSelector(selectIsAuthenticated);
    const user = useSelector(selectCurrentUser);

    const location = useLocation();
    const [menuStyle, setMenuStyle] = useState<MenuKeys>("main");
    const {canInstallPWA, installPWA} = useContext(InstallPWAContext);

    const [feedBackState, setFeedBackState] = useState<FeedBackModel>('CLOSED');
    const reportBugId = useId();


    useEffect(() => {
        const locationParts = location?.pathname.trim()
            .split("/")
            .filter(Boolean)
            .map(s => s.toLowerCase()) // Transform to lower case
        ;

        // Find the menu style that passes the testing function
        setMenuStyle(
            // This will never return undefined, the main key insures this by always returning true
            (Object.entries(menuStyleSelector)
                .find(([_, testers]) =>
                    testers.find(test => test(locationParts))) as [MenuKeys, any])[0]
        );

    }, [location]);

    const toggleMenu = () => setOpen(s => !s);

    const handleSubmitBugReport = (data: ReportBugData) => {
        console.log("Sending bug report: ", data);
        captureFeedback(data);
        setFeedBackState('SENT');
    };

    const feedBackDialogContent: Record<FeedBackModel, ReactNode> = {
        OPEN: (
            <>
                <DialogContent>
                    {feedBackState === 'OPEN' ?
                        <ReportBugForm
                            formId={reportBugId}
                            onSubmit={handleSubmitBugReport}
                        /> :
                        <>
                            <DialogContentText align="center">You message has been sent.</DialogContentText>
                            <DialogContentText align="center">
                                Thank you for taking the time to let us know that the app isn't working as expected.
                            </DialogContentText>
                        </>
                    }
                </DialogContent>
                <DialogActions>
                    <Button color="inherit" onClick={() => setFeedBackState('CLOSED')}>Cancel</Button>
                    <Button
                        disabled={feedBackState === 'SENT'}
                        form={reportBugId}
                        type="submit"
                        variant="contained">Send</Button>
                </DialogActions>
            </>
        ),
        SENT: (
            <>
                <DialogContent>
                    <DialogContentText align="center">You message has been sent.</DialogContentText>
                    <DialogContentText align="center">
                        Thank you for taking the time to let us know that the app isn't working as expected.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        disabled
                        color="inherit" onClick={() => setFeedBackState('CLOSED')}>Cancel</Button>
                    <Button
                        onClick={() => setFeedBackState('CLOSED')}
                        variant="contained">Close</Button>
                </DialogActions>
            </>
        ),
        CLOSED: <></>
    };

    return (
        <>
            <HideOnScroll threshold={100} showContent={showTopMenu}>
                <AppBar
                    position="absolute"
                    sx={menuStyling[menuStyle].appbar}>
                    <Box sx={{
                        width: "100%"
                    }}>
                        <Toolbar sx={[{
                            alignItems: "center"
                        }, menuStyling[menuStyle].toolbar]}>
                            <Typography
                                sx={[
                                    menuStyling[menuStyle].logo,
                                    (theme: Theme) => ({
                                        fontFamily: "tangerine",
                                        fontWeight: 700,
                                        fontSize: "2rem",
                                        textShadow: `1px 1px 3px ${theme.palette.primary.main}, 0px 0px 3px ${theme.palette.primary.main}`
                                    })]}>Aurora Books</Typography>

                            <IconButton
                                onClick={toggleMenu}
                                // size="medium"
                                edge="start"
                                color="inherit"
                                aria-label="Open Menu"
                                sx={[
                                    menuStyling[menuStyle].menu,
                                    {
                                        marginLeft: "auto",
                                    }]}>
                                <MenuIcon fontSize="inherit"/>
                            </IconButton>
                        </Toolbar>
                    </Box>
                </AppBar>
            </HideOnScroll>

            <Drawer
                anchor="right"
                open={open}
                onClose={toggleMenu}>

                <Box sx={{width: 250}}
                     onClick={toggleMenu}
                     aria-label="Close Menu"
                     role="presentation">
                    <List disablePadding>
                        <ListItem disablePadding>
                            <ListItemButton sx={{height: "52.8px"}}>
                                <ListItemIcon>
                                    <ChevronRight/>
                                </ListItemIcon>
                            </ListItemButton>
                        </ListItem>
                    </List>
                    <Divider/>
                    {canInstallPWA &&
                        <>
                            <List disablePadding>
                                <ListItem disablePadding>
                                    <ListItemButton
                                        onClick={installPWA}
                                        aria-label="Install App">
                                        <ListItemIcon>
                                            <InstallMobileIcon/>
                                        </ListItemIcon>
                                        <ListItemText>Install App</ListItemText>
                                    </ListItemButton>
                                </ListItem>
                            </List>
                            <Divider/>
                        </>
                    }
                    <List disablePadding>
                        <ListItem disablePadding>
                            <ListItemButton
                                component={NavLink}
                                role="navigation"
                                aria-label="Home"
                                to={RoutePath.home}>
                                <ListItemIcon>
                                    <HomeIcon/>
                                </ListItemIcon>
                                <ListItemText>Home</ListItemText>
                            </ListItemButton>
                        </ListItem>

                        <ComponentGuard
                            userMustBeAuthenticated
                            hasAny={[authority.viewGroup, authority.viewProduct, authority.viewImage, authority.viewUser]}>
                            <Divider/>
                            <List disablePadding>
                                <ListItem disablePadding>
                                    <ListItemButton component={NavLink} to={RoutePath.admin.route}>
                                        <ListItemIcon>
                                            <AdminPanelSettingsIcon/>
                                        </ListItemIcon>
                                        <ListItemText primary="Admin"/>
                                    </ListItemButton>
                                </ListItem>
                            </List>
                        </ComponentGuard>

                        <ListItem disablePadding>
                            <ListItemButton component={NavLink}
                                            to={RoutePath.cart.route}
                                            role="navigation"
                                            aria-label="Cart">
                                <ListItemIcon>
                                    <ShoppingCart/>
                                </ListItemIcon>
                                <ListItemText>Cart</ListItemText>
                            </ListItemButton>
                        </ListItem>

                    </List>
                    <Divider/>
                    <List disablePadding>
                        <ListItem disablePadding>
                            <ListItemButton
                                role="navigation"
                                aria-label="Settings"
                                component={NavLink}
                                to="/settings">
                                <ListItemIcon>
                                    <SettingsIcon/>
                                </ListItemIcon>
                                <ListItemText primary="Settings"/>
                            </ListItemButton>
                        </ListItem>
                    </List>
                    <Divider/>
                    <List disablePadding>
                        <ListItem disablePadding>
                            <ListItemButton
                                disabled={!isAuthenticated}
                                component={NavLink}
                                role="navigation"
                                aria-label="User Account"
                                to={RoutePath.auth.account.route}>
                                <ListItemIcon>
                                    <AccountBox/>
                                </ListItemIcon>
                                <ListItemText primary="Account"/>
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton
                                disabled={!isAuthenticated}
                                component={NavLink}
                                role="navigation"
                                aria-label="Order History"
                                to={RoutePath.orderHistory.route}>
                                <ListItemIcon>
                                    <History/>
                                </ListItemIcon>
                                <ListItemText primary="Order History"/>
                            </ListItemButton>
                        </ListItem>
                    </List>
                    <Divider/>
                    <List disablePadding>
                        <ListItem disablePadding>
                            <ListItemButton
                                component={NavLink}
                                role="navigation"
                                aria-label="Frequently Asked Questions"
                                to={RoutePath.faq}>
                                <ListItemIcon>
                                    <QuestionMark/>
                                </ListItemIcon>
                                <ListItemText primary="FAQ"/>
                            </ListItemButton>
                        </ListItem>

                        <ListItem disablePadding>
                            <ListItemButton
                                onClick={() => setFeedBackState('OPEN')}
                                aria-label="Create Bug Report">
                                <ListItemIcon>
                                    <BugReportIcon/>
                                </ListItemIcon>
                                <ListItemText primary="Report Bug"/>
                            </ListItemButton>
                        </ListItem>

                        <ListItem disablePadding>
                            <ListItemButton
                                component={NavLink}
                                role="navigation"
                                aria-label="Contact Us"
                                to={RoutePath.contact}>
                                <ListItemIcon>
                                    <AlternateEmailIcon/>
                                </ListItemIcon>
                                <ListItemText primary="Contact"/>
                            </ListItemButton>
                        </ListItem>
                    </List>
                    <Divider/>
                    <List disablePadding>
                        <ListItem disablePadding>
                            <ListItemButton
                                component={NavLink}
                                to={RoutePath.about}>
                                <ListItemIcon>
                                    <InfoOutlinedIcon/>
                                </ListItemIcon>
                                <ListItemText primary="About"/>
                            </ListItemButton>
                        </ListItem>
                    </List>

                    <Divider/>
                    <List disablePadding>
                        <ComponentGuard userMustBeAuthenticated>
                            <LogoutAction
                                user={user}
                                component={LogoutButton}
                            />
                        </ComponentGuard>

                        <ComponentGuard>
                            <ListItem disablePadding>
                                <ListItemButton component={NavLink}
                                                to={RoutePath.auth.signIn.route}
                                                aria-label="Sign In">
                                    <ListItemIcon>
                                        <Login/>
                                    </ListItemIcon>
                                    <ListItemText primary="Sign-In"/>
                                </ListItemButton>
                            </ListItem>
                        </ComponentGuard>
                    </List>
                </Box>
            </Drawer>

            <Dialog open={feedBackState !== 'CLOSED'} aria-modal>
                <DialogTitle>Report Bug</DialogTitle>
                {feedBackDialogContent[feedBackState]}
            </Dialog>
        </>
    );
}

interface LogoutButtonProps {
    onClick: () => void;
}

function LogoutButton({onClick}: LogoutButtonProps) {
    return (
        <ListItem disablePadding>
            <ListItemButton
                aria-label="Log Out"
                onClick={onClick}>
                <ListItemIcon>
                    <Logout/>
                </ListItemIcon>
                <ListItemText primary="Sign-Out"/>
            </ListItemButton>
        </ListItem>
    );
}

export default AppMenu;