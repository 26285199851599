import {useLogoutMutation} from "../AuthSlice";
import {FC, useState} from "react";
import {RoutePath} from "../../../router/RoutePath";
import {useNavigate} from "react-router";
import {Auth} from "../../api/types";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import {transformErrorMessage} from "../../../utils/StringUtils";
import ProgressLoader from "../../../components/ProgressLoader";
import {ResponseError} from "../../api/RtkQueryErrorTransformer";
import { useBroadcast} from "../../../components/BroadcastController";

interface LogoutProps {
    user?: Auth;
    component: FC<{onClick: () => void }>;
}

function LogoutAction({ user, component: Component }: LogoutProps) {

    const navigate = useNavigate();
    const [logoutRequest, {error, isError}] = useLogoutMutation();
    const [showDialog, setShowDialog] = useState(false);
    const { emit } = useBroadcast("AUTH");

    const handleUserLogout = () => {
        if (user) {
            setShowDialog(true);
            logoutRequest({userId: user.id})
                .unwrap()
                .then(response => {
                    navigate(RoutePath.home);
                    setShowDialog(false);
                    // Sync any other tabs
                    emit({ isAuthenticated: false });
                })
                .catch(error => {
                    const responseError = error as ResponseError;
                    console.error("Error logging out");
                    console.error(transformErrorMessage(error));
                });
        }
    };

    return (
        <>
            {   // Display the logout button component, whatever it may be...
                <Component onClick={handleUserLogout} />
            }

            <Dialog open={showDialog} aria-modal>
                <DialogTitle>Logging Out</DialogTitle>
                <DialogContent>
                    {isError ?
                        <>
                            <DialogContentText>Error logging out.</DialogContentText>
                            {transformErrorMessage(error).map((m, i) =>
                                <DialogContentText key={i} fontSize="0.9em">{m}</DialogContentText>
                            )}
                        </> : <ProgressLoader/>
                    }
                </DialogContent>
                <DialogActions>
                    <Button disabled={!isError}>Close</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default LogoutAction;