import {createRoutesFromElements, Route} from "react-router";
import App from "../App";
import Home from "../feature/home/Home";
import RouteGuard from "../components/RouteGuard";
import {RoutePath} from "./RoutePath";
import {authority} from "../feature/auth/AuthTypes";
import CartRoute from "../feature/shopping/routes/CartRoute";
import RootErrorBoundary from "./RootErrorBoundary";
import Fallback from "../Fallback";


export const routeConfig = createRoutesFromElements(
    <Route path="/"
           element={<App />}
           hydrateFallbackElement={<Fallback />}
           errorElement={<RootErrorBoundary/> }>

        <Route index element={<Home/>}/>

        {/****************************      Authentication Routes           ***********************/}
        <Route path="/auth">
            <Route element={<RouteGuard isAuthenticated={false} redirectTo="/"/>}>
                <Route path="sign-in" lazy={() => import("../feature/auth/routes/SignInRoute")}/>
                <Route path={RoutePath.auth.newAccount.name}
                       lazy={() => import("../feature/auth/routes/CreateAccountRoute")}/>
                <Route path={RoutePath.auth.activateAccount.name}
                       lazy={() => import("../feature/auth/routes/ActivateAccountRoute")}/>
                <Route path={RoutePath.auth.resetPassword.name}
                       lazy={() => import("../feature/auth/routes/ResetPasswordRoute")}/>
                <Route path={RoutePath.auth.sendActivateAccountCode.name}
                       lazy={() => import("../feature/auth/routes/SendActivateAccountRoute")}/>
            </Route>
            <Route element={<RouteGuard isAuthenticated
                                        saveLocation
                                        redirectTo={RoutePath.auth.signIn.route}/>}>
                <Route path={RoutePath.auth.account.name}
                       lazy={() => import("../feature/auth/routes/AccountRoute")} />

                <Route path={RoutePath.auth.account.name + "/delete"}
                       lazy={() => import("../feature/auth/routes/DeleteAccountRoute")} />

                <Route path={RoutePath.auth.account.name + "/" + RoutePath.auth.account.deleteRequest.name}
                       lazy={() => import("../feature/auth/routes/DeleteRequestRoute")} />
            </Route>
        </Route>


        {/******************* OAuth redirect *********************/}
        <Route path={RoutePath.auth.oauth.route}
               lazy={() => import("../feature/auth/routes/OAuth2LoginRoute")}
        />

        {/*************************         Product Routes              ************************/}
        <Route path={RoutePath.product.item.route} lazy={() => import("../feature/product/routes/ProductRoute")}/>


        {/**************************            Book Routes              ************************/}
        <Route path={"/book/:slug"}>
            <Route index lazy={() => import("../feature/product/routes/PageViewerRoute")}/>

            <Route element={<RouteGuard isAuthenticated={true} hasAll={[authority.editBook]}/>}>
                <Route path="page/:index" lazy={() => import("../feature/product/routes/EditPageRoute")}/>
                <Route path="pages" lazy={() => import("../feature/product/routes/BookPageTableRoute")}/>
                <Route path="chapters" lazy={() => import("../feature/product/routes/EditChapterRoute")}/>
                <Route path="add-content" lazy={() => import("../feature/product/routes/AddBookContentRoute")}/>
                <Route path="content" lazy={() => import("../feature/product/routes/EditBookContentRoute")} />
            </Route>
        </Route>


        {/* ************************ Cart Routes ********************************************/}
        <Route path={RoutePath.cart.route}>
            <Route index element={<CartRoute/>}/>
            <Route element={<RouteGuard isAuthenticated={true} redirectTo={RoutePath.auth.signIn.route} saveLocation />}>
                <Route path={RoutePath.cart.checkout.name}
                       lazy={() => import("../feature/shopping/routes/CheckoutRoute")}/>
            </Route>
        </Route>

        <Route path={RoutePath.orderHistory.route}
               element={<RouteGuard isAuthenticated redirectTo={RoutePath.auth.signIn.route} saveLocation />}>
            <Route index lazy={() => import("../feature/shopping/routes/OrderHistoryRoute")}/>
        </Route>

        {/* *********************************************************************************** */}
        <Route path={RoutePath.settings} lazy={() => import("../feature/settings/SettingsRoute")}/>
        <Route path={RoutePath.contact} lazy={() => import("../feature/support/routes/ContactRoute")}/>
        <Route path={RoutePath.faq} lazy={() => import("../feature/support/routes/FAQRoute")}/>
        <Route path={RoutePath.about} lazy={() => import("../feature/support/routes/AboutRoute")}/>

        <Route path={RoutePath.documents.route}>
            <Route path={RoutePath.documents.termsOfUse.name}
                   lazy={() => import("../feature/support/routes/TermsRoute")} />

            <Route path={RoutePath.documents.privacyPolicy.name}
                   lazy={() => import("../feature/support/routes/PrivacyRoute")} />
        </Route>

        {/*********************************** Admin Panel **************************************/}
        <Route element={<RouteGuard isAuthenticated
                                    hasAny={[authority.viewUser, authority.viewProduct, authority.viewGroup, authority.viewImage]}
                                    redirectTo="/"/>}>
            <Route path={RoutePath.admin.route} lazy={() => import("../feature/admin/AdminRoute")}>
                <Route index lazy={() => import("../feature/admin/routes/Dashboard")} />

                {/********************************* Product Admin Routes ***************************************/}
                <Route element={<RouteGuard
                    hasAny={[authority.viewProduct, authority.editProduct, authority.deleteProduct, authority.createProduct]}/>}
                    path={RoutePath.admin.product.name}>

                    {/************** List Products Route *******************/}
                    <Route index lazy={() => import("../feature/admin/routes/ListProductsRoute")}/>

                    <Route element={<RouteGuard isAuthenticated hasAll={[authority.createProduct]}/>}>
                        <Route path={RoutePath.admin.product.new.name}
                               lazy={() => import("../feature/admin/routes/CreateProduct")}/>
                    </Route>

                    <Route element={<RouteGuard isAuthenticated hasAll={[authority.editProduct]}/>}>
                        <Route path={":slug/edit"} lazy={() => import("../feature/product/routes/EditProduct")}/>
                    </Route>
                </Route>


                {/**************************           Image Routes                *************************/}
                <Route path={RoutePath.admin.image.name} element={<RouteGuard hasAny={[authority.editImage, authority.createImage]} />}>
                    <Route index lazy={() => import("../feature/admin/routes/ListImages")}/>
                    <Route path=":imageId" lazy={() => import("../feature/admin/routes/ImageRoute")}/>
                    {/* <Route path={RoutePath.image.upload.name} lazy={() => import("./feature/images/routes/UploadImageRoute")}/> */}
                </Route>
            </Route>
        </Route>
    </Route>
);