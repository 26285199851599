import { Breakpoint, SxProps, Theme, styled, IconButton, Box } from "@mui/material";
import {PropsWithChildren, useEffect, useRef} from "react";
import useGetCurBreakpoint from "./hooks/useGetCurBreakpoint";
import useGetNodeSize from "./hooks/useGetNodeSize";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import useSwipe from "./hooks/useSwipe";

interface CarouselProps {
    children: React.ReactElement<any>[];
    responsive?: Partial<Record<Breakpoint, number>>;
    position: number;
    setPosition: (position: number) => void;
}

interface ListProps {
    animate?: boolean;
}

const List = styled('ul', {
})<{ ownerState: ListProps }>(({ theme, ownerState: { animate = true  }={}}) => ({
    overflow: "unset",
    transition: animate ? "transform 400ms ease-in-out 0s" : "none",
    listStyle: "none",
    padding: 0,
    margin: 0,
    display: "flex",
    flexDirection: "row",
    position: "relative",
    willChange: "transform, transition",
}));

const ListItem = styled('li', {

})(({ theme }) => ({
    flex: "1 1 auto",
    position: "relative",
    backfaceVisibility: "hidden",
    display: "list-item",
    unicodeBidi: "isolate"
}));

interface DotBtnProps {
    checked?: boolean;
}

const DotBtn = styled('button', {
    shouldForwardProp: prop => prop !== "checked",
})<DotBtnProps>(({ checked, theme }) => ({
    transition: "backgroundColor",
    transitionDuration: "200ms",
    backgroundColor: checked ? "#461684" : "#9f9f9f",
    border: checked ? "1px solid #989898" : "2px solid #262626", //`1px solid ${checked ? "#989898" : "#171717"}`,
    width: "15px",
    height: "15px",
    borderRadius: "50%",
    margin: 8,
}));


const bp: Breakpoint[] = ["xs", "sm", "md", "lg", "xl"];

export default function Carousel({ position, setPosition, responsive = {}, children }: CarouselProps) {

    responsive = bp.reduce<Partial<Record<Breakpoint, number>>>((acc, key: Breakpoint, index) => {
        acc[key] = responsive[key] ?? (index > 0 ? acc[bp[index - 1]] : 1);
        return acc;
    }, {});

    const ref = useRef(null);
    const nodeSize = useGetNodeSize(ref, 250);
    const hasLoaded = useRef(false);

    useEffect(() => {
       const timer = setTimeout(() => hasLoaded.current = true, 100);
       return () => clearTimeout(timer);
    }, []);

    const curBreakpoint = useGetCurBreakpoint();
    const displayCount = (responsive[curBreakpoint] ?? 1);
    const total = children.length;

    const width = nodeSize.width / displayCount - (12 * (displayCount - 1));

    const handleSetPosition = (p: number) => {
        if (p >= 0 && p < total) {
            setPosition(p);
        }
    }

    const swipe = useSwipe({
        onSwipeLeft: () => handleSetPosition(position - 1),
        onSwipeRight: () => handleSetPosition(position + 1),
        velocity: 200,
    });    

    return (
        <Box sx={{
            position: "relative"
        }}>
            <Box
                ref={ref}
                sx={{
                    display: "flex",
                    alignItems: "center",
                    // overflow: "hidden",
                    position: "relative",
                }}
                {...swipe}
            >
                <List
                    ownerState={{
                        animate: hasLoaded.current
                    }}
                    style={{
                        transform: `translate(-${width * position + (24 * position)}px, 0px)`,
                    }}>
                    {
                        children.map((child, index) => <CarouselItem
                            sx={{ marginRight: 3 }}
                            key={index} width={width}>{child}</CarouselItem>)
                    }
                </List>

                <CarouselBtns
                    position={position}
                    total={total}
                    onPrevious={() => handleSetPosition(position - 1)}
                    onNext={() => handleSetPosition(position + 1)}
                />

            </Box>
            {total > 1 &&
                <Dots onClick={(i) => setPosition(i)} selected={position} total={total} />
            }
        </Box>
    );
}

interface CarouselBtnsProps {
    position: number;
    total: number;
    onNext: () => void;
    onPrevious: () => void;
}

function CarouselBtns({ position, total, onNext, onPrevious }: CarouselBtnsProps) {
    return (
        <>
            {position !== 0 &&
                <IconButton
                    onClick={onPrevious}
                    sx={{
                        position: "absolute",
                        left: 0,
                        top: "50%",
                        marginTop: "-24px"
                    }}><ChevronLeft /></IconButton>
            }
            {position !== total - 1 &&
                <IconButton
                    onClick={onNext}
                    sx={{
                        position: "absolute",
                        right: 0,
                        top: "50%",
                        marginTop: "-24px"
                    }}
                ><ChevronRight /></IconButton>
            }
        </>
    )
}

interface CarouselItemProps {
    sx?: SxProps<Theme>;
    width: number;
}

function CarouselItem({ children, width, sx }: PropsWithChildren<CarouselItemProps>) {
    return (
        <ListItem sx={sx} style={{ width: `${width}px` }}>
            {children}
        </ListItem>
    )
}

interface DotsProps {
    onClick: (selected: number) => void;
    selected: number;
    total: number;
}

function Dots({ onClick, selected, total }: DotsProps) {
    const btns = new Array(total).fill(null);

    return (
        <Box sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexWrap: "nowrap",
        }}>
            {
                btns.map((_, i) => <DotBtn key={i} checked={i === selected} onClick={() => onClick(i)} />)
            }
        </Box>
    );
}


