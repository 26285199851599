import {Container, styled, Breakpoint} from "@mui/material";

interface AppContainerProps {
    topMenu?: boolean;
    bottomMenu?: boolean;
    maxWidth?: Breakpoint;
}

const AppContainer = styled(Container, {
    shouldForwardProp: (prop) =>
        prop !== 'topMenu' && prop !== 'bottomMenu' && prop !== 'maxWidth' && prop !== 'bottomPadding',
})<AppContainerProps>(({theme, maxWidth = "xl", bottomMenu, topMenu}) => ({
    overflowX: "clip",
    paddingTop: theme.spacing(topMenu ? 8 : 0),
    paddingBottom: theme.spacing(bottomMenu ? 8 : 4),
    maxWidth: `${theme.breakpoints.values[maxWidth]}${theme.breakpoints.unit}`,
    [theme.breakpoints.up('md')]: {
        px: theme.spacing(3)
    }
}));



export default AppContainer;