import {apiSlice} from "../api/apiSlice";
import {createSelector, createSlice, current} from "@reduxjs/toolkit";
import {Audited, Collection, Pageable, RequiredFields, Timestamp} from "../api/types";
import {
    Book,
    BookChapter,
    BookPage, BookRedux,
    ChapterPage,
    ChapterPages,
    CreateBook,
    CreatePages,
    CreateProductGroup,
    Product,
    ProductGroup, ProductGroupRedux,
    ProductPrice, ProductRedux, ProductReduxTypes,
    ProductTypes,
    productTypeValue,
    UpdateBook,
    UpdatePage,
    UpdateProductGroup
} from "./ProductTypes";
import {RootState} from "../../store/clientStore";
import {getEpoch} from "../../utils/Utils";
import {useAppSelector} from "../../store/hooks";
import {skipToken} from "@reduxjs/toolkit/query";
import {extendedShoppingApi} from "../shopping/ShoppingSlice";
import {LibraryItem} from "../library/LibraryTypes";
import {selectLoadCount} from "../settings/SettingsSlice";
import {extendedAuthApi} from "../auth/AuthSlice";
import {Image} from "../images/ImageUtils";
import {WritableDraft} from "immer/src/types/types-external";

function isUUID(str?: string) {
    return str && /[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}/.test(str);
}

function getId(item: Product | ProductRedux | Image) {
    return item.id;
}

export const extendedProductApi = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getProducts: builder.query<Collection<Audited<Product>>, Pageable>({
            query: ({
                        page = 0, size = 25, sort,
                        filter = "visible==true",
                        expand
                    } = {}) => ({
                url: '/product',
                method: "GET",
                params: {page: page, size, sort, filter, expand}
            }),
            providesTags: (result, error, page) =>
                result && result.data ? [...result.data.map(product =>
                        ({type: "Product" as const, id: product.id})),
                        {type: "Product", id: "PARTIAL-LIST"}] :
                    [{type: "Product", id: "PARTIAL-LIST"}]
        }),
        getProduct: builder.query<Audited<Product>, { id: string, expand?: string }>({
            query: ({id, expand = ""}) => ({
                url: `/product/${id}`,
                method: "GET",
                params: {expand}
            }),
            providesTags: (result, error, arg) =>
                result ? [{type: "Product", id: result.id}, "Product"] : ["Product"],
        }),
        createGroup: builder.mutation<Audited<ProductGroup>, CreateProductGroup>({
            query: (body) => ({
                url: `/group`,
                method: "POST",
                body
            }),
            invalidatesTags: (result, error, args) =>
                result ? [{type: "Group", id: result.id},
                    {type: "Group", id: "PARTIAL-LIST "}] : ["Group"]
        }),
        updateGroup: builder.mutation<Audited<ProductGroup>, UpdateProductGroup>({
            query: ({id, ...body}) => ({
                url: `/group/${id}`,
                method: "POST",
                body
            }),
            invalidatesTags: (result, error, args) =>
                result ? [{type: "Group", id: args.id}, {type: "Group", id: "PARTIAL-LIST"}] : ["Group"]
        }),
        getAllGroups: builder.query<Collection<Audited<ProductGroup>>, Pageable>({
            query: (params) => ({
                url: `/group`,
                method: "GET",
                params
            }),
            providesTags: (result, error, page) =>
                result && result.data ? [...result.data.map(series =>
                        ({type: "Group" as const, id: series.id})),
                        {type: "Group", id: "PARTIAL-LIST"}] :
                    [{type: "Group", id: "PARTIAL-LIST"}],
        }),
        getGroup: builder.query<Audited<ProductGroup>, { id: string, expand?: string }>({
            query: ({id, expand}) => ({
                url: `/group/${id}`,
                method: "GET",
                params: {expand}
            }),
            providesTags: (result, error, arg) =>
                result ? [{type: "Group", id: result.id}, "Group"] : ["Group"]
        }),
        deleteGroup: builder.mutation<void, { id: string }>({
            query: ({id}) => ({
                url: `/group/${id}`,
                method: "DELETE"
            }),
            invalidatesTags: (result, error, args) =>
                result ? [{type: "Group", id: args.id},
                    {type: "Group", id: "PARTIAL-LIST "}] : ["Group"]
        }),
        createBook: builder.mutation<Audited<Product>, CreateBook>({
            query: (body) => ({
                url: `/book`,
                method: "POST",
                body
            }),
            invalidatesTags: (result, error, arg) =>
                result ? [{type: "Book", id: result.id}, {type: "Book", id: "PARTIAL-LIST"}] : ["Book"],
        }),
        getBook: builder.query<Audited<Book>, { id: string, expand?: string }>({
            query: ({id, expand = ""}) => ({
                url: `/book/${id}`,
                method: "GET",
                params: expand ? {expand} : {}
            }),
            transformResponse(response: Audited<Book>, meta, arg) {
                // if ("pages" in response) {
                //     response.pages?.sort((p1, p2) => p1.number - p2.number);
                // }
                if ("chapters" in response) {
                    response.chapters?.sort((c1, c2) => c1.index - c2.index);
                }
                return response;
            },
            providesTags: (result, error, arg) =>
                result ? [{type: "Book", id: result.id}, "Book"] : ["Book"],

        }),
        getBooks: builder.query<Collection<Book>, Pageable>({
            query: ({
                        page = 0, size = 20, sort,
                        filter,
                        expand
                    } = {}) => ({
                url: '/book',
                method: "GET",
                params: {page: page, size, sort, filter, expand}
            }),
            providesTags: (result, error, page) =>
                result && result.data ? [...result.data.map(product =>
                        ({type: "Product" as const, id: product.id})),
                        {type: "Product", id: "PARTIAL-LIST"}] :
                    [{type: "Product", id: "PARTIAL-LIST"}],
        }),
        updateBook: builder.mutation<Book, UpdateBook & { id: string }>({
            query: ({id, ...body}) => ({
                url: `/book/${id}`,
                method: "POST",
                body
            }),
            invalidatesTags: (result, error, arg) =>
                result ? [{type: "Product", id: result.id}, "Product"] : ["Product"]
        }),
        deleteBook: builder.mutation<void, { id: string }>({
            query: ({id}) => ({
                url: `/book/${id}`,
                method: "DELETE"
            }),
            invalidatesTags: (result, error, arg) =>
                [{type: "Product", id: arg.id}, "Product"]
        }),
        getPages: builder.query<Collection<BookPage>, {
            bookId: string,
            page: number | string,
            size: number,
            sort?: string
        }>({
            query: ({bookId, ...params}) => ({
                url: `/book/${bookId}/page`,
                method: "GET",
                params
            }),
            providesTags: (result, error, arg) =>
                result ? [...result.data.map(page =>
                    ({
                        type: "BookPage" as const,
                        id: compositeId(page.bookId, page.index)
                    })
                ), {type: "BookPage", id: "PARTIAL-LIST"}] : ["BookPage"]
        }),
        getPage: builder.query<BookPage, { bookId: string, page: string, expand?: string }>({
            query: ({bookId, page, ...params}) => ({
                url: `/book/${bookId}/page/${page}`,
                method: "GET",
                params
            }),
            providesTags: (result, error, arg) =>
                result ? [{type: "BookPage", id: compositeId(result.bookId, result.index)}, "BookPage"] : ["BookPage"]
        }),
        getChapterPages: builder.query<ChapterPage[], string>({
            query: (bookId) => ({
                url: `/book/${bookId}/pages`,
                method: "GET",
            }),
            transformResponse: (result: ChapterPages | null, meta) => {
                if (result) {
                    return result.sort((a, b) => a.index - b.index);
                } else return [];
            },
            providesTags: ["ChapterPages"]
        }),
        createPages: builder.mutation<BookPage[], CreatePages>({
            query: ({bookId, pages}) => ({
                url: `/book/${bookId}/page`,
                method: "POST",
                body: pages
            }),
            invalidatesTags: (result, error, arg) =>
                result ? [...result.map(page =>
                    ({
                        type: "BookPage" as const,
                        id: compositeId(page.bookId, page.index)
                    })
                ), {type: "BookPage", id: "PARTIAL-LIST"}, "BookChapter", "ChapterPages"] : ["BookPage"]
        }),
        updatePage: builder.mutation<BookPage, UpdatePage>({
            query: ({bookId, index, ...body}) => ({
                url: `/book/${bookId}/page/${index}`,
                method: "POST",
                body
            }),
            invalidatesTags: (result, error, arg) =>
                result ? [{
                    type: "BookPage",
                    id: compositeId(result.bookId, result.index)
                }, "BookPage", "ChapterPages"] : ["BookPage"]
        }),
        deletePage: builder.mutation<void, { bookId: string, index: number }>({
            query: ({bookId, index}) => ({
                url: `/book/${bookId}/page/${index}`,
                method: "DELETE"
            }),
            invalidatesTags: (result, error, arg) =>
                [{type: "BookPage", id: compositeId(arg.bookId, arg.index)}, "BookPage", "ChapterPages"]
        }),
        getBookChapters: builder.query<BookChapter[], { bookId: string, expand?: string }>({
            query: ({bookId, expand}) => ({
                url: `/book/${bookId}/chapter`,
                method: "GET",
                params: {
                    expand
                }
            }),
            transformResponse: (result: BookChapter[], meta) => {
                return result.sort((a, b) => a.index - b.index)
                    .map(c => {
                        if ("pages" in c) {
                            c.pages?.sort((a, b) => a - b)
                        }
                        return c;
                    })
            },
            providesTags: (result, error, arg) =>
                result ? [...result.map(chapter =>
                    ({
                        type: "BookChapter" as const,
                        id: compositeId(arg.bookId, chapter.index)
                    })), "BookChapter"] : ["BookChapter"]
        }),
        getBookChapter: builder.query<BookChapter, { bookId: string, number: number, expand?: string }>({
            query: ({bookId, number, expand}) => ({
                url: `/book/${bookId}/chapter/${number}`,
                method: "GET",
                params: {
                    expand
                }
            }),
            providesTags: (result, error, arg) =>
                result ? [{
                    type: "BookChapter",
                    id: compositeId(arg.bookId, result.index)
                }, "BookChapter"] : ["BookChapter"]
        }),
        createBookChapter: builder.mutation<BookChapter, RequiredFields<BookChapter, "bookId">>({
            query: ({bookId, ...body}) => ({
                url: `/book/${bookId}/chapter`,
                method: "POST",
                body
            }),
            invalidatesTags: (result, error, arg) =>
                result ? [{type: "BookChapter", id: compositeId(arg.bookId, result.index)},
                    "BookChapter", "ChapterPages"] : ["BookChapter"]
        }),
        updateBookChapter: builder.mutation<Omit<BookChapter, "bookId">, RequiredFields<BookChapter, "bookId">>({
            query: ({bookId, index, ...body}) => ({
                url: `/book/${bookId}/chapter/${index}`,
                method: "POST",
                body
            }),
            invalidatesTags: (result, error, arg) =>
                result ? [{
                    type: "BookChapter",
                    id: compositeId(arg.bookId, result.index),
                }, "BookChapter", "ChapterPages"] : ["BookChapter"]
        }),
        deleteBookChapter: builder.mutation<void, { bookId: string, index: number }>({
            query: ({index, bookId}) => ({
                url: `/book/${bookId}/chapter/${index}`,
                method: "DELETE",
            }),
            invalidatesTags: (result, error, args) =>
                [{type: "BookChapter", id: args.bookId},
                    "BookChapter", "ChapterPages"]
        })
    })
});


export interface InitialState {
    item: Record<string, ProductReduxTypes>;
    itemIdMap: Record<string, string>; // Map provided slug to id resolution

    book: Record<string, {
        page: Record<number, BookPage> & { available?: number };
        chapter: Record<number, BookChapter>;
    }>;
}

export const initialState: InitialState = {
    item: {},
    itemIdMap: {},
    book: {}
} as const;

function isBook<T extends Product | ProductRedux>(item: ProductTypes | ProductReduxTypes): item is T {
    return (item as (T)).type === productTypeValue.DIGITAL_BOOK;
}

const productSlice = createSlice({
    name: "product",
    initialState,
    reducers: {},
    extraReducers: builder =>
        builder
            .addMatcher(extendedProductApi.endpoints.getProduct.matchFulfilled,
                (state, {payload}: { payload: Product }) => {
                    state.item[payload.id] = extractProduct(payload, state);
                    state.itemIdMap[payload.slug] = payload.id;
                })
            .addMatcher(extendedProductApi.endpoints.getProducts.matchFulfilled,
                (state, {payload}) => {

                    // TODO: This will not work, it will remove any other item that was not included in the query.
                    // const item = payload.data.reduce<Record<string, Product & ImageIds>>((acc, cur) => {
                    //     acc[cur.id] = {
                    //         ...state.item[cur.id],
                    //         ...cur,
                    //         images: []
                    //     };
                    //
                    //     return acc;
                    // }, {});
                    //
                    // state.item = item;
                    //
                    // state.itemIdMap = Object.values(item)
                    //     .reduce<Record<string, string>>((acc, cur) => {
                    //         acc[cur.slug] = cur.id;
                    //         return acc;
                    //     }, {});

                    payload.data.forEach((product) => {
                        state.item[product.id] = extractProduct(product, state);
                        state.itemIdMap[product.slug] = product.id;
                    });
                })
            .addMatcher(extendedProductApi.endpoints.getBook.matchFulfilled,
                (state, {
                    payload,
                }) => {
                    state.item[payload.id] = extractProduct(payload, state);
                    state.itemIdMap[payload.slug] = payload.id;
                })
            .addMatcher(extendedProductApi.endpoints.getBooks.matchFulfilled,
                (state, {payload}) => {
                    payload.data.forEach(item => {
                        state.item[item.id] = extractProduct(item, state);
                        state.itemIdMap[item.slug] = item.id;
                    });
                })
            .addMatcher(extendedProductApi.endpoints.getPage.matchFulfilled,
                (state, {payload: {index, bookId, ...rest}, type, meta}) => {
                    // If the bookId is provided in the response use it, if not, convert provided id into a UUID, the provided id could be the book slug, or the UUID.
                    if (state.book[bookId] === undefined) {
                        state.book[bookId] = {
                            page: {available: 0},
                            chapter: {}
                        };
                    }
                    state.book[bookId].page[index] = {index, bookId, ...rest}
                })
            .addMatcher(extendedProductApi.endpoints.getPages.matchFulfilled,
                (state, {payload, type, meta}) => {
                    // This does create another problem, if query pages by book id (not slug), the request may fail, if the book slug is unknown. -- in order to do that though, we
                    // would have to know the book id, and in addition the slug, so it should work either way.
                    const bookId = isUUID(meta.arg.originalArgs.bookId) ? meta.arg.originalArgs.bookId : state.itemIdMap[meta.arg.originalArgs.bookId] || payload.data[0]?.bookId; // Check payload as a last resort, most likely it will not have the bookId property

                    if (bookId !== undefined) {
                        if (state.book[bookId] === undefined)
                            state.book[bookId] = {
                                page: {available: 0},
                                chapter: {}
                            }

                        payload.data?.forEach(({index, ...rest}) =>
                            state.book[bookId].page[index] = {index, ...rest});

                        state.book[bookId].page.available = payload.totalElements;
                    } else {
                        console.error("BookId is undefined in getPages.matchFulfilled. This should not have happened.");
                    }
                })
            .addMatcher(extendedProductApi.endpoints.getBookChapters.matchFulfilled,
                (state, {payload, meta}) => {
                    const bookId = isUUID(meta.arg.originalArgs.bookId) ? meta.arg.originalArgs.bookId : state.itemIdMap[meta.arg.originalArgs.bookId] || payload[0]?.bookId;

                    if (bookId !== undefined) {
                        if (state.book[bookId] === undefined) {
                            state.book[bookId] = {
                                page: {available: 0},
                                chapter: {}
                            };
                        }

                        payload.forEach(({index, ...rest}) => {
                            state.book[bookId].chapter[index] = {
                                ...rest,
                                index,
                            };
                        });
                    } else {
                        console.error("BookId is undefined in getBookChapters.matchFulfilled. This should not have happened.");
                    }
                })
            .addMatcher(extendedProductApi.endpoints.getBookChapter.matchFulfilled,
                (state, {payload: {index, bookId, ...rest}, meta}) => {
                    const bookID = bookId || isUUID(meta.arg.originalArgs.bookId) ? meta.arg.originalArgs.bookId : state.itemIdMap[meta.arg.originalArgs.bookId];

                    if (bookID !== undefined) {
                        if (state.book[bookID] === undefined)
                            state.book[bookID] = {
                                page: {available: 0},
                                chapter: {}
                            };

                        state.book[bookID].chapter[index] = {index, ...rest, bookId}
                    } else {
                        console.error("BookId is undefined in getBookChapter.matchFulfilled. This should not have happened.");
                    }
                })
            .addMatcher(extendedProductApi.endpoints.getAllGroups.matchFulfilled,
                (state, {payload, meta}) => {

                    payload.data.forEach(({components, ...product}) => {

                        state.item[product.id] = {
                            ...extractProduct(product, state),
                            components: components ? Object.values(components)
                                .reduce((acc, {groupId, componentId, quantity, position}) => ({
                                    ...acc,
                                    [componentId]: {groupId, componentId, quantity, position}
                                }), {}) : undefined,
                        };

                        if (components) {
                            Object.values(components)
                                .map(c => c.component)
                                .filter(isProduct)
                                .forEach((product: Product) => {
                                    state.item[product.id] = extractProduct(product, state);
                                    state.itemIdMap[product.slug] = product.id;
                                });
                        }

                        state.itemIdMap[product.slug] = product.id;
                    });
                })
            .addMatcher(extendedProductApi.endpoints.getGroup.matchFulfilled,
                (state, {payload, meta}) => {
                    state.item[payload.id] = extractProduct(payload, state);
                    state.itemIdMap[payload.slug] = payload.id;
                })
            .addMatcher(extendedShoppingApi.endpoints.checkout.matchFulfilled,
                (state, {payload, meta}) => {
                    Object.keys(payload?.items ?? {}).forEach(itemId => {
                        if (state.book[itemId]?.page)
                            delete state.book[itemId].page.available;
                    })
                })
            .addMatcher(extendedAuthApi.endpoints.logout.matchFulfilled,
                (state) => {
                    // TODO: update this, we don't have to remove all data, just what they would not have access to
                    // state.book = {};
                })
            .addMatcher(extendedAuthApi.endpoints.login.matchFulfilled,
                (state) => {
                    const books = current(state.book);
                    Object.keys(books).forEach(bookId => {
                        // Remove this value on user sign in, because they may own
                        // this book, so there available limit may be different
                        delete state.book[bookId].page.available;
                    });
                })

});

function extractProduct<T extends Product>(product: T, state: WritableDraft<InitialState>) {
    return {
        ...(state.item[product.id] ? current(state.item[product.id]) : {}),
        ...product,
        images: product.images ? product.images.map(getId) : state.item[product.id] ? current(state.item[product.id])?.images : []
    };
}


function isProduct<T extends Product | ProductRedux>(product: T | undefined): product is T {
    return (product as T) !== undefined;
}

export function selectProductId(state: RootState, productId: string): string {
    return state.product.itemIdMap[productId] || productId;
}

export function selectCanPurchase(state: RootState, productId: string | undefined): boolean {
    if (!productId)
        return false;

    return !state.shopping.purchases[selectProductId(state, productId)];
}


function compositeId(id: string, index: number) {
    return id + "-" + index;
}

export const selectProduct = (state: RootState, productId?: string): ProductRedux | undefined => {
    return productId ? state.product.item[selectProductId(state, productId)] : undefined;
}


export const selectPage = (state: RootState, bookId?: string, index?: string | number): undefined | BookPage => {
    if (bookId && index) {
        const id = selectProductId(state, bookId);
        if (id) {
            if (typeof index === "string") {
                index = Number.parseInt(index);
                if (isNaN(index))
                    return undefined;
            }
            return state.product.book[id]?.page?.[index];
        }
    }
    return undefined;
}

export const selectCurPage = (state: RootState, bookId?: string) => {
    if (bookId) {
        const id = selectProductId(state, bookId);
        if (id) {
            const libraryEntry = state.library.book[id];
            if (libraryEntry)
                return state.product.book[id]?.page?.[libraryEntry.curPage];
        }
    }
}


export const selectAllBooks = createSelector(
    (state: RootState) => state.product.item,
    (products): Record<string, BookRedux> =>
        Object.values(products).filter(isBookType).reduce((acc, cur) => ({
            ...acc,
            [cur.id]: cur
        }), {})
);


export const selectAllGroups = createSelector(
    (state: RootState) => state.product.item,
    (products): Record<string, ProductGroupRedux> =>
        Object.values(products).filter(isGroupType).reduce((acc, cur) => ({
            ...acc,
            [cur.id]: cur
        }), {})
);

export const selectAllProducts = (state: RootState) => state.product.item;

export function isGroupType<T extends ProductGroup | ProductGroupRedux>(product?: ProductTypes | ProductReduxTypes): product is T {
    return product?.type === productTypeValue.GROUP;
}

export function isBookType<T extends Book | BookRedux>(product?: ProductTypes | ProductReduxTypes): product is T {
    return product?.type === productTypeValue.DIGITAL_BOOK;
}

export const selectProductPrice = createSelector(
    (state: RootState, productId: string | undefined) => productId ? state.product.item[selectProductId(state, productId)] : undefined,
    (product): ProductPrice | undefined => {
        if (!product || product.prices === undefined || product.prices.length === 0)
            return undefined;

        // TODO: Include device offset
        const now = getEpoch();

        const prices = product.prices.filter(p => p.effectiveDate <= now && p.expiresDate > now)
            .sort((a, b) => a.effectiveDate - b.effectiveDate);

        return prices[0];
    }
)

// TODO: fix this function. it is a mess, Didn't i already do this?
/**
 * Will return an object of pages, index my page number, for the specified book by slug.
 * @Param limit - defines how many pages to grab from the page number
 */
export const selectPageRange = createSelector(
    (state: RootState, bookId: string): Record<string, BookRedux> => selectAllBooks(state),
    (state: RootState, bookId: string): LibraryItem | null => state.library.book[selectProductId(state, bookId)] || null,
    (state: RootState, bookId: string): Record<number, BookPage> | null => state.product.book[selectProductId(state, bookId)]?.page || null,

    (state: RootState, bookId: string): string => selectProductId(state, bookId),
    (state: RootState, bookId: string): number => selectLoadCount(state) / 2,

    (books: Record<string, BookRedux>, libraryItem: LibraryItem | null, pages: Record<number, BookPage> | null, bookId, limit): Record<number, BookPage> => {
        const result: Record<number, BookPage> = {};
        const book = books[bookId];

        const curPage = libraryItem?.curPage ?? 1;

        if (book && libraryItem && pages) {
            // If totalPages is not available, select the limit
            const totalPages = book.totalPages;
            const max = totalPages ? (curPage + limit) < totalPages ? curPage + limit : totalPages : limit;

            for (let i = curPage; i < max; ++i) {
                result[i] = pages[i];
            }
        }

        return result;
    });

export function isTimestamp<T>(item: Timestamp<T> | number): item is Timestamp<T> {
    return (item as Timestamp<T>).timestamp !== undefined;
}

// export const selectAllSeries = createSelector(
//     (state: RootState) => state.product.group,
//     (series) => Object.values(series));
//
// export const selectSeries = (state: RootState, seriesId: string) => state.product.group[selectSeriesId(state, seriesId)];

// export const filteredSelectSeries = createSelector(
//     (state: RootState) => state.product.group,
//     (series) => Object.values(series).filter(isTimestamp)
// );

// The function does not determine if a book has a next page,
// All it does is determine if a next page is available local.
// That could mean that the book does not have a next page,
// or that a next page, has not yet been loaded.
export const hasNextPage = (state: RootState, bookId: string, page: string | number) => {
    const id = state.product.itemIdMap[bookId] || bookId;
    if (state.product.book[id] === undefined)
        return undefined;

    if (typeof page === "string") {
        page = Number.parseInt(page);
    }

    if (isNaN(page))
        return undefined;

    return Boolean(state.product.book[bookId].page[page + 1]);
}

export const selectAvailableBookPages = (state: RootState, productId?: string): number =>
    productId ? (state.product.book[selectProductId(state, productId)]?.page?.available ?? 0) : 0;

export const selectTotalBookPages = (state: RootState, productId?: string): number =>
    productId ? ((state.product.item[selectProductId(state, productId)] as BookRedux)?.totalPages ?? 0) : 0;

// export const selectBookChapters = (state: RootState, bookId?: string) => {
//     if (!bookId)
//         return;
//     const id = selectProductId(state, bookId);
//     if (Boolean(id)) {
//         return state.product.book[id]?.chapter;
//     }
// };

export const selectBookChapters = createSelector(
    (state: RootState, bookId?: string) => bookId ? state.product.book[selectProductId(state, bookId)] : undefined,
    (book: {
        page: Record<number, BookPage> & { available?: number };
        chapter: Record<number, BookChapter>;
    } | undefined): BookChapter[] => Object.values(book?.chapter ?? {})
);

export default productSlice.reducer;

export const {
    useGetProductsQuery,
    useGetProductQuery,
    useGetBookQuery,
    useGetBooksQuery,
    useGetGroupQuery,
    useGetAllGroupsQuery,
    useCreateGroupMutation,
    useUpdateGroupMutation,
    useDeleteGroupMutation,
    useCreateBookMutation,
    useDeleteBookMutation,
    useUpdateBookMutation,
    useGetPageQuery,
    useGetPagesQuery,
    useGetChapterPagesQuery,
    useCreatePagesMutation,
    useUpdatePageMutation,
    useDeletePageMutation,
    useGetBookChapterQuery,
    useGetBookChaptersQuery,
    useCreateBookChapterMutation,
    useUpdateBookChapterMutation,
    useDeleteBookChapterMutation,
} = extendedProductApi;


// need to figure out expand properties, and how to verify they are present
function useGetProduct({id, expand}: { id: string; expand: "images" | "creators" }) {

    const product = useAppSelector(state => selectProduct(state, id));

    useGetProductQuery(product ? skipToken : {id, expand});

    return [product];
}

