import {
    AddShoppingCart as AddShoppingCartIcon,
    AutoStories as AutoStoriesIcon,
    ShoppingCartCheckout as ShoppingCartCheckoutIcon
} from '@mui/icons-material';
import {Box, IconButton, Link, SxProps, Typography, Unstable_Grid2 as Grid, Theme} from "@mui/material";
import {skipToken} from "@reduxjs/toolkit/query";
import React, {PropsWithChildren, useContext} from "react";
import {Link as RouterLink, useNavigate} from "react-router";
import {RoutePath} from "../../../router/RoutePath";
import ProgressLoader from "../../../components/ProgressLoader";
import {useAppSelector} from "../../../store/hooks";
import {transformErrorMessage} from "../../../utils/StringUtils";
import {selectAuthorities} from "../../auth/AuthSlice";
import {Image} from "../../images/ImageUtils";
import ImageGroup from "../../images/components/ImageGroup";
import {selectCurrentPageNumber, selectLibraryEntry} from "../../library/LibrarySlice";
import {CartManagerContext} from "../../shopping/CartManager";
import {selectOwnsProduct} from "../../shopping/ShoppingSlice";
import {
    selectCanPurchase,
    selectProduct,
    selectProductPrice,
    selectTotalBookPages,
    useGetProductQuery
} from "../ProductSlice";
import {canOpenBook} from '../productUtilies';
import ProgressIndicator from "./ProgressIndicator";
import {ProductRedux} from '../ProductTypes';
import {selectImagesByIds} from "../../images/ImageSlice";


interface ProductItemProps {
    id?: string;
    sx?: SxProps;
    position?: number;
    product?: ProductRedux;
}

export default React.forwardRef(
    function ProductItem({id, position, sx}: ProductItemProps, ref) {

        const navigate = useNavigate();
        const cartContext = useContext(CartManagerContext);
        const authorities = useAppSelector(selectAuthorities);
        const product = useAppSelector(state => selectProduct(state, id));
        const images = useAppSelector(state => selectImagesByIds(state, product?.images));
        const price = useAppSelector(state => selectProductPrice(state, id));
        const canPurchase = useAppSelector(state => selectCanPurchase(state, id));
        const ownsProduct = useAppSelector(state => selectOwnsProduct(state, product?.id));

        const {
            isLoading,
            isError,
            error
        } = useGetProductQuery(id ?
            {
                id,
                expand: "images"
            } : skipToken);


        const libraryEntity = useAppSelector(state => selectLibraryEntry(state, product?.id));
        const itemInCart = cartContext.itemInCart(id);
        const curPage = useAppSelector(state => selectCurrentPageNumber(state, product?.slug));
        const totalPages = useAppSelector(state => selectTotalBookPages(state, product?.id));
        const canViewPages = canOpenBook(product, authorities);

        const handleAddToCart = (event: React.MouseEvent) => {

            if (product && price) {
                // Probable need to fix this...
                cartContext.addItem({
                    productId: product!.id,
                    price,
                    quantity: 1
                });

                navigate(RoutePath.cart.route);
            }
        };

        // Display a loader if the product is not serverStore in redux, and a network request is running
        if (!product && isLoading)
            return (
                <ImgContainer>
                    <ProgressLoader/>
                </ImgContainer>
            );

        if (!product && isError)
            return (
                <ImgContainer>
                    <Grid container spacing={2}>
                        <Grid xs={12}>
                            <Typography variant="h3" align="center">Error Loading Product</Typography>
                        </Grid>

                        <Grid xs={12}>
                            <Typography align="center" fontSize="0.9rem">Details:</Typography>
                            {transformErrorMessage(error).map((m, i) =>
                                <Typography align="center" key={i} fontSize="0.9rem">{m}</Typography>)
                            }
                        </Grid>
                    </Grid>
                </ImgContainer>
            );

        return (
            <Box>
                {
                    position &&
                    <Typography fontSize="0.8rem" sx={{opacity: 0.8}}>Book {numbToWord(position)}</Typography>
                }
                <ImgContainer
                    images={Object.values(images)}
                    sx={[...(Array.isArray(sx) ? sx : [sx]), {
                        width: "100%",
                        aspectRatio: "16 / 9"
                    }]}>
                    {
                        canViewPages &&
                        <Link
                            sx={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                            }}
                            component={RouterLink}
                            to={libraryEntity ? `/book/${product?.slug}` : `/product/${product?.slug}`}/>
                    }
                    <>
                        {
                            !ownsProduct && (
                                itemInCart ?
                                    <IconButton
                                        disabled={!product}
                                        sx={{
                                            position: "absolute",
                                            right: 0,
                                            top: 0,
                                            mt: 1,
                                            mr: 1,
                                            backgroundColor: "rgba(0,0,0,0.15)",
                                            border: "1px solid rgba(255,255,255,0.2)",
                                            '&:hover': {
                                                backgroundColor: "rgba(54,54,54,0.6)"
                                            }
                                        }}
                                        component={RouterLink}
                                        to="/cart">
                                        <ShoppingCartCheckoutIcon/>
                                    </IconButton>
                                    :
                                    <IconButton
                                        disabled={!canPurchase}
                                        sx={{
                                            position: "absolute",
                                            right: 0,
                                            top: 0,
                                            mt: 1,
                                            mr: 1,
                                            backgroundColor: "rgba(0,0,0,0.15)",
                                            border: "1px solid rgba(255,255,255,0.2)",
                                            '&:hover': {
                                                backgroundColor: "rgba(54,54,54,0.6)"
                                            }
                                        }}
                                        onClick={handleAddToCart}>
                                        <AddShoppingCartIcon/>
                                    </IconButton>
                            )}
                    </>

                    <ProgressIndicator
                        percentage={(curPage && totalPages) ? curPage / totalPages : 0}
                        sx={{
                            position: "absolute",
                            bottom: 0, left: 0, right: 0,
                            borderTop: "1px solid #ddd",
                        }}>
                        <Box sx={theme => ({
                            margin: 1,
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center"
                        })}>

                            <Box component={RouterLink}
                                 to={`/product/${product?.slug}`}
                                 color="inherit"
                                 sx={{textDecoration: "none"}}>

                                <Typography fontSize="1.2rem" lineHeight={1.3}>{product?.title}</Typography>
                                {
                                    product?.subTitle &&
                                    <Typography fontSize="0.8rem" lineHeight={1.3}>{product.subTitle}</Typography>
                                }
                            </Box>

                            <IconButton component={RouterLink}
                                        disabled={!canViewPages}
                                        to={`/book/${product?.slug}`}>
                                <AutoStoriesIcon/>
                            </IconButton>
                        </Box>
                    </ProgressIndicator>
                </ImgContainer>
            </Box>
        )
            ;
    });

interface ContainerProps {
    images?: Image[];
    sx?: SxProps<Theme>;
}

function ImgContainer({images, sx, children}: PropsWithChildren<ContainerProps>) {

    return (
        <ImageGroup
            images={images}
            aspect="16x9"
            sx={[(theme) => ({
                position: "relative",
                border: "1px solid #ddd",
                backgroundColor: "#333",
                boxShadow: "4px 4px 10px rgba(0,0,0,0.6)",
                borderRadius: "8px",
                width: "100%",
            }),
                ...(Array.isArray(sx) ? sx : [sx])
            ]}>
            {children}
        </ImageGroup>
    );
}

function numbToWord(i: number) {
    switch (i) {
        case 1:
            return "One";
        case 2:
            return "Two";
        case 3:
            return "Three";
        case 4:
            return "Four";
        case 5:
            return "Five";
        case 6:
            return "Six";
        case 7:
            return "Seven";
        case 8:
            return "Eight";
        case 9:
            return "Nine";
        case 10:
            return "Ten";
        default:
            console.error("Cannot convert number to word, ", i);
            return "";
    }
}