import {CartItem, CreateCartItem} from "../ShoppingTypes";
import {selectProduct, selectProductPrice, useGetProductQuery} from "../../product/ProductSlice";
import React, {PropsWithChildren} from "react";
import {alpha, Box, Paper, Stack, SxProps, Theme, Typography, Unstable_Grid2 as Grid} from "@mui/material";
import ImageGroup from "../../images/components/ImageGroup";
import DisplayPrice from "../../../components/DisplayPrice";
import {transformErrorMessage} from "../../../utils/StringUtils";
import ProgressLoader from "../../../components/ProgressLoader";
import {useAppSelector} from "../../../store/hooks";
import {selectImagesByIds} from "../../images/ImageSlice";


type CartItemProps = {
    item: CreateCartItem | CartItem;
    actionIcon?: React.ReactNode;
    sx?: SxProps<Theme>;
};

export default React.forwardRef(
    function CartItemComponent({item, actionIcon, sx}: CartItemProps, ref) {

        const product = useAppSelector(state => selectProduct(state, item.productId));
        const images = useAppSelector(state => selectImagesByIds(state, product?.images));

        const price = useAppSelector(state => selectProductPrice(state, item.productId));

        const {isFetching, isError, error} = useGetProductQuery({id: item.productId, expand: "images"});

        if (isFetching)
            return (
                <ComponentPaper>
                    <Grid xs={12}>
                        <ProgressLoader sx={{height: "auto"}}/>
                    </Grid>
                </ComponentPaper>
            );

        if (isError)
            return (
                <ComponentPaper>
                    <Grid xs={12} sx={{p: 2}}>
                        {   // TODO improve error message in cart items
                            transformErrorMessage(error).map((m, i) =>
                                <Typography key={i}>{m}</Typography>
                            )
                        }
                    </Grid>
                </ComponentPaper>
            );

        return (
            <ComponentPaper>
                <Grid xs={4} sx={theme => ({
                    display: "flex",
                    alignItems: "center",
                    maxWidth: "120px",
                    [theme.breakpoints.up("md")]: {
                        maxWidth: "150px"
                    }
                })}>
                    <ImageGroup
                        aspect="3x4"
                        images={Object.values(images)}
                        sx={theme => ({
                            borderRadius: "4px",
                            border: `1px solid ${alpha('#ffffff', .2)}`
                        })}
                    />
                </Grid>

                <Grid xs>
                    <Stack spacing={1} justifyContent="space-between" height="100%">
                        <Stack direction="row" justifyContent="space-between">
                            <Box>
                                <Typography variant="h3" component="p">{product?.title}</Typography>
                                {product?.subTitle &&
                                    <Typography>{product.subTitle}</Typography>
                                }
                            </Box>
                            {actionIcon}
                        </Stack>

                        <Typography
                            sx={theme => ({
                                fontSize: "0.75rem",
                                color: theme.palette.text.secondary,

                            })}
                        >{product?.shortDescription}</Typography>

                        <Stack direction="row" justifyContent="space-between" alignItems="flex-end">
                            <Typography fontSize="0.9rem">Quantity: {item.quantity}</Typography>
                            <Typography
                                display="inline-block"
                                align="right"
                                lineHeight="1"
                                fontSize="1.4rem">
                                <DisplayPrice>{price?.basePrice}</DisplayPrice>
                            </Typography>
                        </Stack>
                    </Stack>
                </Grid>
            </ComponentPaper>
        )
            ;
    });


function ComponentPaper({children, sx}: PropsWithChildren<{ sx?: SxProps<Theme>; }>) {

    return (
        <Grid
            component={Paper}
            container
            spacing={2}
            sx={[theme => ({
                backgroundColor: alpha(theme.palette.background.paper, 0.9),
                [theme.breakpoints.up("sm")]: {
                    padding: .5,
                }
            }),
                ...(Array.isArray(sx) ? sx : [sx])
            ]}>
            {children}
        </Grid>
    );
}
