import React from 'react';
import {CssBaseline} from "@mui/material";
import ThemeContextProvider from "./Theme";
import {hydrateRoot} from 'react-dom/client';
import {routeConfig} from "./router/Router";
import {createBrowserRouter, RouterProvider} from "react-router";
import {Provider} from "react-redux";
import {persistor, clientStore} from "./store/clientStore";
import {PersistGate} from "redux-persist/integration/react";
import "@fontsource/libre-caslon-text";
import ProgressLoader from "./components/ProgressLoader";
import ReactGA from "react-ga4";
import reportWebVitals from "./reportWebVitals";
import {onCLS, onINP, onLCP} from "web-vitals";
import * as Sentry from "@sentry/react";
import {BroadcastController} from "./components/BroadcastController";


const router = createBrowserRouter(routeConfig);


Sentry.init({
    dsn: "https://1b3eed0dbb70569433fcb460b14ba681@o4507744315113472.ingest.us.sentry.io/4507744356073472",
    environment: import.meta.env.MODE,
    enabled: process.env.NODE_ENV === "production",
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.browserProfilingIntegration(),
        Sentry.replayIntegration(),
        Sentry.feedbackIntegration({
            colorScheme: "system",
            isEmailRequired: true,
            autoInject: false,
        })
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Set profilesSampleRate to 1.0 to profile every transaction.
    // Since profilesSampleRate is relative to tracesSampleRate,
    // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
    // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
    // results in 25% of transactions being profiled (0.5*0.5=0.25)
    profilesSampleRate: 1.0,
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});


function Main() {

    return (
        <React.StrictMode>
            <ThemeContextProvider>
                <CssBaseline enableColorScheme/>
                <Provider store={clientStore}>
                    <PersistGate
                        loading={<ProgressLoader sx={{height: "100vh", width: "100%"}}/>}
                        persistor={persistor}>
                        <BroadcastController>
                            <RouterProvider router={router}/>
                        </BroadcastController>
                    </PersistGate>
                </Provider>
            </ThemeContextProvider>
        </React.StrictMode>
    );
}

hydrateRoot(document.getElementById("root") as HTMLElement, <Main/>);


if (import.meta.env.PROD) {
    console.log("Initializing Analytics");

    ReactGA.initialize("G-TQGLM5X75Z");

    onCLS(reportWebVitals);
    onINP(reportWebVitals);
    onLCP(reportWebVitals);
}
