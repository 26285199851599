import {
    BaseQueryArg,
    BaseQueryError,
    BaseQueryFn,
    createApi,
    FetchArgs,
    fetchBaseQuery,
    FetchBaseQueryError,
    retry
} from "@reduxjs/toolkit/query/react";
import {RootState} from "../../store/clientStore";


type CsrfError = {
    status: 403;
    data: {
        apiVersion?: string;
        code: "invalid_csrf";
        message?: string;
        status?: string;
    }
}

function isCsrfError(error: unknown): error is CsrfError {
    return typeof error == "object" &&
        (error as CsrfError).status === 403 &&
        (error as CsrfError)?.data?.code === "invalid_csrf";
}


const baseQuery = fetchBaseQuery({
    baseUrl: import.meta.env.MODE === "test" ? "http://localhost:5173/api" : "/api",
    prepareHeaders(headers, {type, getState}) {
        if (!headers.has("accept"))
            headers.set("accept", "application/json");

        if (type === "mutation") {
            headers.set("x-xsrf-token", (getState() as RootState).auth.csrf?.token ?? "");
        }
        return headers;
    }
});

// Verify the user has a csrf token before sending any non-GET request
// If any request fails because of an invalid CSRF token, request a new token from the server,
// and retry the request one last time.
const csrfBaseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = retry(
    async function (args, api, extraOptions) {
        if (api.type === "mutation") {
            // If the csrf token is null, get it first
            if ((api.getState() as RootState).auth.csrf == null) {
                // Initiate the query when the token is null
                await api.dispatch(apiSlice.endpoints.getCSRF.initiate());
            }
            const result = await baseQuery(args, api, extraOptions);

            if (result.error?.status === 403 && isCsrfError(result.error)) {
                // Get a new token and update the redux store
                try {
                    const csrfToken = await baseQuery('/auth/csrf', api, extraOptions);
                    api.dispatch({
                        type: "auth/setCsrf",
                        payload: csrfToken.data,
                    });
                } catch (error) {
                    console.error("Error fetching csrf token.");
                }
            }
            // Return the result of the original query, if the query fails because of a token error, retry query again
            return result;
        }
        return baseQuery(args, api, extraOptions);
    }, {
        // Return if csrf code is invalid, this function will not return on any other condition
        retryCondition: (error: BaseQueryError<BaseQueryFn>, args: BaseQueryArg<BaseQueryFn>, {
            attempt,
            baseQueryApi
        }) =>
            baseQueryApi.type === "mutation" && isCsrfError(error) && attempt < 2
    });


const staggeredCsrfBaseQuery = retry(
    async function (args, api, extraOptions) {
        const result = await csrfBaseQuery(args, api, extraOptions);

        if (result.error?.status === 400 || result.error?.status === 401 || result.error?.status === 403 || result.error?.status === 404
            || result.error?.status === 500) {
        }

        return result;
    }, {
        maxRetries: 5
    });

type CsrfTokenResponse = { token: string };


export const apiSlice = createApi({
    reducerPath: 'api',
    baseQuery: csrfBaseQuery, // staggeredCsrfBaseQuery,
    endpoints: builder => ({
        getCSRF: builder.query<CsrfTokenResponse, void>({
            query: () => ({
                url: '/auth/csrf',
            }),
            providesTags: ["CSRF"]
        }),
    }),
    tagTypes: ["CSRF", "User", "Auth", "UserProfile", "Product", "Book",
        "BookPage", "BookChapter", "BookPageRange", "ChapterPages", "Image", "LibraryEntity",
        "Cart", "CartItem", "Group", "Purchases", "NotificationPref"]
});
