import {Button, IconButton, Typography, Unstable_Grid2 as Grid, Box} from "@mui/material";
import {Delete as DeleteIcon} from "@mui/icons-material";
import React, {useContext} from "react";
import {CartManagerContext} from "../CartManager";
import CartItemComponent from "../components/CartItemComponent";
import DisplayPrice from "../../../components/DisplayPrice";
import {Link} from "react-router";
import {RoutePath} from "../../../router/RoutePath";
import ComponentGuard from "../../../components/ComponentGuard";
import MenuSpacer from "../../../components/MenuSpacer";
import AppContainer from "../../../components/AppContainer";
import ConfirmAction from "../../../components/ConfirmAction";


function CartRoute() {
    const cartManager = useContext(CartManagerContext);

    return (
        <AppContainer>
            <MenuSpacer/>

            <Box display="flex"
                 flexDirection="column"
                 justifyContent="space-between"
                 sx={{
                     minHeight: "calc(100vh - 150px)"
                 }}>
                <Grid container spacing={2}>
                    <Grid xs={12}>
                        <Typography variant="h1" fontSize="1.4rem">Cart</Typography>
                    </Grid>
                    {
                        Object.entries(cartManager.getCart()[0]?.items ?? {})
                            .map(([key, item]) =>

                                <Grid xs={12} key={key}
                                      sx={{ marginY: 1}}>

                                    <CartItemComponent
                                        item={item}
                                        actionIcon={
                                            <ConfirmAction
                                                title="Remove Item From Cart?"
                                                onCommit={() => cartManager.deleteItem(item.productId)}
                                                component={({onClick}) => (
                                                    <IconButton
                                                        size="small"
                                                        onClick={onClick}
                                                        sx={{
                                                            borderRadius: "20%",
                                                            border: (theme) => `1px solid ${theme.palette.text.disabled}`
                                                        }}
                                                        disabled={!item.productId}>
                                                        <DeleteIcon/>
                                                    </IconButton>
                                                )}
                                                actions={({onConfirm, onCancel}) => (
                                                    <>
                                                        <Button
                                                            color="inherit"
                                                            // variant="outlined"
                                                            onClick={onCancel}>Cancel</Button>
                                                        <Button
                                                            variant="contained"
                                                            color="error"
                                                            onClick={onConfirm}>Remove Item</Button>
                                                    </>
                                                )}
                                            />}
                                    />
                                </Grid>
                            )
                    }
                </Grid>

                <Grid container spacing={2} sx={{pb: 2}}>

                    <Grid xs={12} display="flex" justifyContent="space-between">
                        <Typography>Subtotal</Typography>
                        <Typography align="right">
                            <DisplayPrice>{cartManager.getCart()[0].subTotal}</DisplayPrice>
                        </Typography>
                    </Grid>

                    <Grid xs={12} display="flex">
                        <ComponentGuard userMustBeAuthenticated={true}
                                        alternative={
                                            <Button
                                                component={Link}
                                                to={RoutePath.auth.signIn.route}
                                                size="large"
                                                variant="contained"
                                                sx={{marginLeft: "auto"}}
                                                state={{path: RoutePath.cart.route}}
                                            >Sign in
                                            </Button>
                                        }>
                            <Button
                                disabled={Object.values(cartManager.getCart()[0]?.items ?? {}).length === 0}
                                component={Link}
                                to={RoutePath.cart.checkout.route}
                                size="large"
                                variant="contained"
                                sx={{marginLeft: "auto"}}
                            >Checkout</Button>
                        </ComponentGuard>
                    </Grid>
                </Grid>
            </Box>
        </AppContainer>
    );
}

export default CartRoute;